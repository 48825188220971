import { kvClear, kvCreateStore, kvDel, kvGet, kvKeys, kvSet } from './KeyValueStorage';

/**
 * Stores forms in IndexedDB.
 */
export class IndexedDbFormStorage {
  #customStore;

  /**
   * Constructor.
   * @param dbName the database name.
   * @param storeName the store name.
   */
  constructor(dbName, storeName) {
    this.#customStore = kvCreateStore(dbName, storeName);
  }

  /**
   * Init IndexedDB with specified initial data.
   * @param initialData the initial data.
   */
  async init(initialData) {
    for (const formName in initialData) {
      const formNames = await this.getFormNames();
      if (formNames.indexOf(formName) === -1) {
        await this.saveForm(formName, initialData[formName]);
      }
    }
  }

  /**
   * @inheritDoc
   */
  async getForm(formName) {
    const formValue = await kvGet(formName, this.#customStore);
    if (!formValue) throw new Error(`Cannot find form '${formName}'`);
    return formValue;
  }

  /**
   * @inheritDoc
   */
  getFormNames() {
    return kvKeys(this.#customStore);
  }

  /**
   * @inheritDoc
   */
  removeForm(formName) {
    return kvDel(formName, this.#customStore);
  }

  /**
   * @inheritDoc
   */
  async saveForm(formName, formValue) {
    return await kvSet(formName, formValue, this.#customStore);
  }

  /**
   * Clears all values in the storage.
   * @returns the Promise with the result of the work.
   */
  async clear() {
    return await kvClear(this.#customStore);
  }
}
