import React, { useEffect, useState } from 'react';
import { TextInput, Button, Dropdown } from "pepsico-ds";
import './auth.css';
import { Controller, set, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CountDownTimer from './count-down-timer';

const OtpForm = ({ otpOptions, sendDataToParent }) => {
    const { t } = useTranslation();
    const { REACT_APP_API_ENDPOINT } = process.env;
    console.log(otpOptions)
    let availalbleOptions = {};
    let mobileEmail = '';
    const [otpInput, setOtpInput] = useState('');
    const [isOtpSent, setIsOtpSent] = useState('');
    const [otpTypesSelected, setOtpTypesSelected] = React.useState(true);
    const [retryCount, setRetryCount] = React.useState(0);
    const [resendCount, setResendCount] = React.useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [inititalTime, setInitialTime] = useState(0);
    const [showTimer, setShowTimer] = useState(false);
    let otpOptionSelected;
    if (otpOptions?.otpType === 'SMS') {
        availalbleOptions = [otpOptions?.['mobile']];
        availalbleOptions = availalbleOptions?.map((x, i) => ({ displayText: x, id: i, isBadge: false }))
        let mobileNumber = otpOptions?.['mobile'];
        mobileEmail = mobileNumber.substring(0, 3) + "*****" + mobileNumber.substring(mobileNumber.length - 3)
    }
    if (otpOptions?.otpType === 'CALL') {
        availalbleOptions = [otpOptions?.['phoneNumber']];
        availalbleOptions = availalbleOptions?.map((x, i) => ({ displayText: x, id: i, isBadge: false }))
        let phoneNumber = otpOptions?.['phoneNumber'];
        mobileEmail = phoneNumber.substring(0, 3) + "*****" + phoneNumber.substring(phoneNumber.length - 3)
    }
    if (otpOptions?.otpType === 'Email') {
        let emailId = otpOptions?.['email'];
       // mobileEmail = phoneNumber.substring(0, 3) + "*****" + phoneNumber.substring(phoneNumber.length - 3)
        mobileEmail = emailId.substring(0, 3) + "********" + emailId.substring(emailId.length - 11);
    }
    const handleOtpChange = (event) => {
        console.log(event);
        const onlyNumbersOtp = event.target.value.replace(/[^0-9]/g, ''); // Regex to remove non-numeric characters
        setOtpInput(onlyNumbersOtp);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          console.log('Enter key pressed ✅');
          const onlyNumbersOtp = event.target.value.replace(/[^0-9]/g, ''); // Regex to remove non-numeric characters
          setOtpInput(onlyNumbersOtp);
          verifyOtp();
        }
      };
    
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
        };
    
        const getCount = () => {
          console.log("Count fetched!");
        };
        getCount();
        showResendTimer();
        window.addEventListener("resize", handleResize);
    
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);

    const {
        handleSubmit,
        register,
        control,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        defaultValues: {
            my_selections: [],
        },
    })

    const getCount = () => {
        axios.post(REACT_APP_API_ENDPOINT + 'getRetryCount ', {
            "gpid": otpOptions.gpid
        }).then(response => {
            if (response?.data?.status === 'Success') {
                setRetryCount(response.data.pepmyidMCustomField);
            }
            else {
                setError("otpNumber", {
                    type: "manual",
                    message: response?.data?.message,
                })
            }
        }).then(data => console.log({ data }))
            .catch(error => {
                // Handle error (e.g., display error message)
                console.error('Error fetching data:', error?.response?.data?.message);
            });
    }

    const updateRetryCount = () => {
        axios.post(REACT_APP_API_ENDPOINT + 'lockChallengeFactors', {
            "gpid": otpOptions.gpid,
            retryCount: retryCount
        }).then(response => {
            if (response?.data?.status === 'Success') {
                setResendCount(0);
                setRetryCount(response.data.pepmyidMCustomField);
            }
        }).then(data => console.log({ data }))
            .catch(error => {
                // Handle error (e.g., display error message)
                console.error('Error fetching data:', error?.response?.data?.message);
            });
    }

    const changeOtpType = (e) => {
        console.log('Selected Value:', e);
    }

    const resendOTP = () => {
        setResendCount((resendCount) => resendCount + 1);
        console.log('Resend Count:', resendCount);
        if (resendCount >= 3) {
            setRetryCount((retryCount) => retryCount + 1);
            //updateRetryCount()  //not applicable for OTPs
        }
        if (otpOptions?.otpType === 'SMS') {
            sendDataToParent('resendotpsms', {});
        }
        if (otpOptions?.otpType === 'CALL') {
            sendDataToParent('resendotpcall', {});
        }
        if (otpOptions?.otpType === 'Email') {
            sendDataToParent('resendotpEmail', {});
        }
        showResendTimer();
    }

    const sendOtpType = (e) => {
        // sendDataToParent('otp', { data: e });
        sendSmsOtp(e);
    }
    const sendCallOtpType = (e) => {
        // sendDataToParent('otp', { data: e });
        sendCallOtp(e);
    }

    const showOtpSelection = () => {
        sendDataToParent('previous', otpInput);
    }

    const verifyOtp = () => {
        if (otpOptions?.otpType === 'SMS') {
            verifyOtpSms(otpInput);
        }
        if (otpOptions?.otpType === 'CALL') {
            verifyOtpCall(otpInput);
        }
        if (otpOptions?.otpType === 'Email') {
            verifyOtpEmail(otpInput);
        }
    }

    const showResendTimer = () => {
        setShowTimer(true);
        setInitialTime(30);
        setTimeout(() => {
            setShowTimer(false);
            setInitialTime(0);
        }, 30000);
    }
    const sendSmsOtp = (formData) => {
        // setGpidErrorMessage('');
        axios.post(REACT_APP_API_ENDPOINT + 'sendOTPSMS', {
            "gpid": otpOptions.gpid
        }).then(response => {
            // setres(response.data)
            if (response?.data?.responseStatus === 'Success') {
                setOtpTypesSelected(true);
                showResendTimer();
            }
            else {
                console.log('error')
                setError("my_selections", {
                    type: "manual",
                    message: response?.data?.responseMessage,
                })
                setIsOtpSent(response?.data?.responseMessage);
            }
        }).then(data => console.log({ data }))
            .catch(error => {
                // Handle error (e.g., display error message)
                console.error('Error fetching data:', error?.response?.data?.message);
                // setGpidErrorMessage(error?.response?.data?.message);
                // return { "gpid": "22221108", "secQuestions": ["What was your first pet's name?", "What was your high school mascot?"], "mobile": "+917604877905" }
            });
    }

    const sendCallOtp = (formData) => {
        // setGpidErrorMessage('');
        axios.post(REACT_APP_API_ENDPOINT + 'sendOTPVOICE', {
            "gpid": otpOptions.gpid,
            "resendOTP": false
        }).then(response => {
            // setres(response.data)
            if (response?.data?.responseStatus === 'Success') {
                setOtpTypesSelected(true);
                showResendTimer();
            }
            else {
                console.log('error')
                setError("my_selections", {
                    type: "manual",
                    message: response?.data?.responseMessage,
                })
                setIsOtpSent(response?.data?.responseMessage);
            }
        }).then(data => console.log({ data }))
            .catch(error => {
                // Handle error (e.g., display error message)
                console.error('Error fetching data:', error?.response?.data?.message);
                // setGpidErrorMessage(error?.response?.data?.message);
                // return { "gpid": "22221108", "secQuestions": ["What was your first pet's name?", "What was your high school mascot?"], "mobile": "+917604877905" }
            });
    }

    const verifyOtpSms = (formData) => {
        axios.post(REACT_APP_API_ENDPOINT + 'verifyOTP', {
            "gpid": otpOptions.gpid,
            "otp": formData,
            "channelType" : "Mobile"
        }).then(data => {
            console.log({ data })
            if (data?.data?.responseStatus === 'Success' && data?.data?.responseMessage === 'OTP Verified successfully') {
                clearErrors();
                sendDataToParent('otpVerified', {});
            }
            else {
                if (data?.data?.responseMessage) {
                    setError("otpNumber", {
                        type: "manual",
                        message: data?.data?.responseMessage,
                    })
                }
                else {
                    setError("otpNumber", {
                        type: "manual",
                        message: data?.data?.errorMessage,
                    })
                }
                sendDataToParent('otpVerified', { status: 'Failed' });
            }
        }).catch(error => {
            setError("otpNumber", {
                type: "manual",
                message: error?.response?.data?.errorMessage,
            })
            console.error('Error fetching data:', error?.response?.data?.errorMessage);
        });
    }

    const verifyOtpCall = (formData) => {
        axios.post(REACT_APP_API_ENDPOINT + 'verifyOTP', {
            "gpid": otpOptions.gpid,
            "otp": formData,
            "channelType" :"VOICE"
        }).then(data => {
            console.log({ data })
            if (data?.data?.responseStatus === 'Success' && data?.data?.responseMessage === 'OTP Verified successfully') {
                clearErrors();
                sendDataToParent('callVerified', {});
            }
            else {
                setError("otpNumber", {
                    type: "manual",
                    message: data?.data?.responseMessage,
                })
                sendDataToParent('callVerified', { status: 'Failed' });
            }
        }).catch(error => {
            setError("otpNumber", {
                type: "manual",
                message: error?.response?.data?.errorMessage,
            })
            sendDataToParent('callVerified', { status: 'Failed' });
            console.error('Error fetching data:', error?.response?.data?.errorMessage);
        });
    }

    const verifyOtpEmail = (formData) => {
        axios.post(REACT_APP_API_ENDPOINT + 'verifyOTPEmail', {
            "gpid": otpOptions.gpid,
            "otp": formData,
         }).then(data => {
            console.log({ data })
            if (data?.data?.status === 'Success' && data?.data?.message === 'OTP Verified successfully') {
                clearErrors();
                sendDataToParent('otpVerified', {});
            }
            else {
                setError("otpNumber", {
                    type: "manual",
                    message: data?.data?.errorMessage,
                })
                sendDataToParent('otpVerified', { status: 'Failed' });
            }
        }).catch(error => {
            setError("otpNumber", {
                type: "manual",
                message: error?.response?.data?.errorMessage,
            })
            console.error('Error fetching data:', error?.response?.data?.errorMessage);
        });
    }
    // const handleChange = (event) => {
    //     console.log(event);
    //     const onlyNumbers = event.target.value.replace(/[^0-9]/g, ''); // Regex to remove non-numeric characters
    //     setOtpInput(onlyNumbers);
    //     if (event.target.value?.length === 8) {

    //     }
    //   }

    return (
        <div className='w-full' >
            {!otpTypesSelected &&
                <form onSubmit={handleSubmit(sendOtpType)}>
                    <div>
                        <h1>Password Reset</h1>
                    </div>
                    <div>
                        <div className='info-container'>
                            <p> You will receive a one time password every time you try to reset your password.</p>
                        </div>
                        <hr className='custom-hr' />
                        {/* <Dropdown
                    childList={availalbleOptions}
                    dropdownHeader=""
                    helperText=""
                    isRequired
                    label="Example"
                    selection="single"
                    setSelectedValue={() => changeOtpType()}
                    size="medium"
                /> */}
                        <div style={{ display: 'grid', justifyContent: 'center' }}>
                            <div style={{ display: 'grid', justifyContent: 'center' }}>
                                <Controller
                                    control={control}
                                    {...register('my_selections', {
                                        required: 'This is required',
                                    })}
                                    render={({ field }) => (
                                        <Dropdown
                                            selectedValue={field.id}
                                            label="One time code delviery method"
                                            isRequired={true}
                                            childList={availalbleOptions}
                                            error={!!errors.my_selections?.message}
                                            setSelectedValue={field.onChange}
                                        />
                                    )}
                                />
                                {errors.my_selections && <div className='fieldError'>{errors.my_selections.responseMessage}</div>}
                                {/* <Button variant="primary" >
                            Send
                        </Button> */}
                            </div>
                            <div>
                                <span>Code is valid for 15 minutes only, standard rates may apply.</span>
                                <br /><br />
                                <Button className='full-button' size="medium" text='Send' onClick={sendOtpType} />
                            </div>
                        </div>
                    </div>
                </form>}
            {otpTypesSelected &&
                <div>
                    <div className='info-container'>
                        <p>Please enter the OTP received.</p>
                    </div>
                    <hr className='custom-hr' />
                    <div className={!isMobile ? "w-1-2 m-auto" : "w-full m-auto"}>
                        <div style={{ textAlign: 'start' }}>
                            <div className='disabledText'>We sent a code via {otpOptions?.otpType} to:</div>
                            <div>{mobileEmail}</div>
                            <div style={{ marginTop: '12px' }}>
                                {/* <TextInput type="text" value={otpInput} onChange={(e) => handleOtpChange(e)} {...register('otpNumber')} showLabelIcon required label={t('Enter OTP received')} /> */}
                                <TextInput type="text" required showLabelIcon label={t('Enter OTP received')} value={otpInput} onChange={handleOtpChange} onKeyDown={handleKeyDown}/>
                                {errors.otpNumber && <div className='fieldError'>{errors.otpNumber.message}</div>}
                            </div>
                        </div>
                        <br />
                        {!isMobile ? < br /> : null}
                        <div style={{ gap: '8px' }} className={!isMobile ? "d-flex" : "d-grid"}>
                            <Button className='full-button' size="medium" text='Previous' onClick={showOtpSelection} />
                            <Button className='full-button' size="medium" text='Continue' onClick={verifyOtp} />
                        </div>
                        {!isMobile ? < br /> : null}<br />
                        {showTimer && <div className='fieldInfo'>Resend code will be enabled in <CountDownTimer initialTime={inititalTime} /> seconds</div>}

                        {!showTimer && <div>
                            <div className='disabledText'>Didn't receive your code?</div>
                            <div style={{ fontSize: '14px', fontWeight: '700' }} className='link' onClick={resendOTP}><a>Send code again</a></div>
                        </div>}
                      </div>
                </div>}
        </div>

    );
};

export default OtpForm;