import React, { useEffect, useState } from 'react';
import { Button, Dropdown, TextInput } from "pepsico-ds";
import './auth.css';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Footer from '../common/footer';
import forge from "node-forge";
import CryptoJS from 'crypto-js'
import axios from 'axios';
import CountDownTimer from './count-down-timer';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';
import { Icon } from 'react-icons-kit';

const publicKey = `-----BEGIN RSA PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsoZnlKyNcmMo3zrdx3KGc+/+fk1Jn7zYVyENg8hf6+SQ+p03Mzx8+hO6QUWgkJtlQfWBabZWMolGcpr37QTs4LJc6IEdvy24b8SjWM8AXj6II+SRpOR1m7W9I0eD16+6/nLWBpB5FAxCjrODC//BZVvGStXnTfQZc+/E2EamK21DneFkJaC9FlTwoNSy+LM5bjf6Q2YjWuX06uePzaAOLnycyhA8fHMoHwBQLdzfpKwRKMOr3VnDilem9RH9zGi44rg8y2g2TY1WzvsStmDM8NduV5aOnwzFMo+BTugfLhdP4ZmYR/BX5WLfPmxkAEI5fVNONBCuzoJsyX424wMLlwIDAQAB
    -----END RSA PUBLIC KEY-----`;
export const encrypt = (plainText, key) => {
    const publicKey = forge.pki.publicKeyFromPem(key);
    return forge.util.encode64(
        publicKey.encrypt(plainText, "RSA-OAEP", {
            md: forge.md.md5.create(),
            mgf1: {
                md: forge.md.sha1.create()
            }
        })
    )
};

export const encryptAES = (msg) => {
    const key = CryptoJS.lib.WordArray.random(8).toString();
    const iv = CryptoJS.lib.WordArray.random(8).toString();

    // encrypt some bytes using GCM mode
    const cipher = forge.cipher.createCipher('AES-GCM', key);
    cipher.start({
        iv: iv,
        additionalData: 'nvn', // optional
        tagLength: 128 // optional, defaults to 128 bits
    });
    cipher.update(forge.util.createBuffer(msg));
    cipher.finish();
    const encrypted = cipher.output;
    const encodedB64 = forge.util.encode64(encrypted.data);
    const tag = cipher.mode.tag;
    const tagB64 = forge.util.encode64(tag.data);
    return {
        key: encrypt(key, publicKey),
        iv: iv,
        tag: tagB64,
        encrypt: encodedB64,
    }
};

const SecurityForm = ({ securityOptions, retrySecCount, sendDataToParent }) => {
    console.log('securityOptions');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const { REACT_APP_API_ENDPOINT } = process.env;
    const { t } = useTranslation();
    const [securityCheck, setSecurityCheck] = useState('');
    const [type, setType] = useState('password');
    const [typeQ2, setTypeQ2] = useState('password');
    const [icon, setIcon] = useState(eyeOff);
    const [iconQ2, setIconQ2] = useState(eyeOff);
    const [counter, setCounter] = useState(1);
    const [disableButton, setDisableButton] = useState(false);
    const [inititalTime, setInitialTime] = useState(0);
    const [showTimer, setShowTimer] = useState(false);
    const [lockSecQ, setLockSecQ] = useState(false);
    let availalbleQuestions = [];
    if (securityOptions?.['secQuestions']?.length > 0) {
        availalbleQuestions = securityOptions?.['secQuestions'];
    }

    const { register, handleSubmit, setError, clearErrors,formState: { errors }, watch } = useForm();
    const onSecurityQstnsSubmit = (e) => {
        verifySecondaryQuestions(e)
    }

    const handleToggle = (index) => {
        if (type === 'password' && index === '1') {
            setIcon(eye)
            setType('text')
        } else if (index === '1') {
            setIcon(eyeOff)
            setType('password')
        }
        if (typeQ2 === 'password' && index === '2') {
            setIconQ2(eye)
            setTypeQ2('text')
        } else if (index === '2') {
            setIconQ2(eyeOff)
            setTypeQ2('password')
        }
    }

    const verifySecondaryQuestions = (formData) => {
        setSecurityCheck('');
        let body = {};
        body[availalbleQuestions[0]] = encrypt(formData['question1'], publicKey);
        body[availalbleQuestions[1]] = encrypt(formData['question2'], publicKey)
        axios.post(REACT_APP_API_ENDPOINT + 'user/verifySecQ', {
            "gpid": securityOptions.gpid,
            "questionAnswer": body
        }).then(response => {
            console.log(response.data);
            if (response.data?.status === 'Failed') {
                setError("question1", {
                    type: "manual",
                    message: response.data?.message,
                });
                setError("question1", {
                    type: "manual",
                    message: response.data?.message,
                });
                sendDataToParent('security', { status: 'Failed' });
                setCounter(counter + 1);
                console.log(counter);
                if (counter === 1) {
                    setDisableButton(true);
                    setShowTimer(true);
                    setInitialTime(10);
                    setTimeout(() => {
                        setDisableButton(false); setShowTimer(false);
                    }, 10000);
                } else if (counter === 2) {
                    setDisableButton(true);
                    setShowTimer(true);
                    setInitialTime(30);
                    setTimeout(() => {
                        setDisableButton(false); setShowTimer(false);
                    }, 30000);
                    // } else if (counter === 3) {
                    //     setDisableButton(true);
                    //     setShowTimer(true);
                    //     setInitialTime(70);
                    //     setTimeout(() => { setDisableButton(false); setShowTimer(false);
                    //     }, 70000);
                } else if (counter === 3) {
                    setDisableButton(true);
                    setShowTimer(false);
                    setLockSecQ(true);
                    updateSecQuestionsRetryCount();
                }
            }
            else {
                setCounter(1);
                setLockSecQ(false);
                setShowTimer(false);
                setDisableButton(false);
                sendDataToParent('security', {});
            }
        })
            .catch(error => {
                // Handle error (e.g., display error message)
                console.error('Error fetching data:', error?.response?.data?.message);
                setSecurityCheck(error?.response?.data?.message);
                // return { "gpid": "22221108", "secQuestions": ["What was your first pet's name?", "What was your high school mascot?"], "mobile": "+917604877905" }
            });
    }
    const showPrevious = () => {
        sendDataToParent('previous', {});
    }
    let retrySecQCount = 0;

    const getSecurityQuestionRetryCount = () => {
        axios.post(REACT_APP_API_ENDPOINT + 'getRetryCount ', {
            "gpid": securityOptions.gpid
        }).then(response => {
            if (response?.data?.status === 'Success') {
                retrySecQCount = response.data.pepmyidMCustomField;
            }
            else {
                setError("otpNumber", {
                    type: "manual",
                    message: response?.data?.message,
                })
            }
        }).then(data => console.log({ data }))
            .catch(error => {
                // Handle error (e.g., display error message)
                console.error('Error fetching data:', error?.response?.data?.message);
            });
    }

    const updateSecQuestionsRetryCount = () => {
        axios.post(REACT_APP_API_ENDPOINT + 'lockChallengeFactors', {
            "gpid": securityOptions.gpid,
            retryCount: retrySecCount + 1
        }).then(response => {
            if (response?.data?.status === 'Success') {
                setCounter(0);
                sendDataToParent('security', { status: 'Failed', retryCount: retrySecCount + 1 });
            }
        }).then(data => console.log({ data }))
            .catch(error => {
                // Handle error (e.g., display error message)
                console.error('Error fetching data:', error?.response?.data?.message);
            });
    }
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <div className='w-full'>
            <div className='info-container'>
                <p> Please verify your identitiy by answering your security questions.</p>
            </div>
            <hr className='custom-hr' />
            <div id='password_container'>
                <form className={!isMobile ? "w-1-2 m-auto" : "w-full m-auto"} onSubmit={handleSubmit(onSecurityQstnsSubmit)} >
                    <div style={{ display: 'flex' }}>
                        <TextInput type={type} value={this} {...register('question1')} showLabelIcon required label={t(availalbleQuestions[0])} style={{ width: '-webkit-fill-available' }} />
                        <div className="flex justify-around items-center " id='hide-icon' onClick={() => handleToggle('1')}>
                            <Icon className="visible" icon={icon} size={16} onClick={() => handleToggle('1')} />
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <TextInput type={typeQ2} value={this} {...register('question2')} showLabelIcon required label={t(availalbleQuestions[1])} style={{ width: '-webkit-fill-available' }} />
                        <div className="flex justify-around items-center " id='hide-icon' onClick={() => handleToggle('2')}>
                            <Icon className="visible" icon={iconQ2} size={16} onClick={() => handleToggle('2')} />
                        </div>
                    </div>
                    {errors.question1 && <div className='fieldError'>{errors.question1.message}</div>}

                    <br /> {!isMobile ? < br /> : null}
                    <div style={{ gap: '8px' }} className={!isMobile ? "d-flex" : "d-grid"}>
                        <Button className='full-button' size="medium" text='Previous' onClick={showPrevious} />
                        <Button type="submit" size='medium' className="full-button" disabled={disableButton}>Continue</Button>
                    </div>
                    {showTimer && <div className='fieldError'>Retry will be enabled in <CountDownTimer initialTime={inititalTime} /> seconds</div>}
                    {lockSecQ && <div className='fieldError'>This option is not available now since you<br /> have entered the incorrect answers four times</div>}
                    {!isMobile ? < br /> : null}
                </form>
            </div >
            {/* <Footer /> */}

        </div>
    )
};

export default SecurityForm;