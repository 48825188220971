import React, { useEffect, useState } from 'react';
import { Avatar, Header, Icon, NotificationMenu, ProfileMenu } from "pepsico-ds";
import { useOktaAuth } from '@okta/okta-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router";

function HeaderView() {
    const { oktaAuth } = useOktaAuth();
    const { t, i18n } = useTranslation();
    const [langSelected, setLangSelected] = useState('en');
    const [userName, setUserName] = useState('');
    const navigate = useNavigate();
    const data = JSON.parse(localStorage.getItem('okta-token-storage'));

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Set user name from the token storage
    if (!userName && data?.accessToken?.claims?.displayname) {
        // console.log(userName)
        // console.log('Data:', data?.accessToken);
        // console.log('Data:', data?.accessToken?.claims);
        // console.log('Data:', data?.accessToken?.claims?.displayname);
        setUserName(data?.accessToken?.claims?.displayname);
        console.log('User  1:', userName);
        console.log('data:', data);

    }
    console.log('User Name 3:', userName);

    const changeLanguage = (lng) => {
        // setLangSelected(lng);
        i18n.changeLanguage(lng);  // Assuming you're using i18next
    };

    const langSelection = (lang) => {
        console.log('Clicked on language:', lang);
        const languageCode = lang === 0 ? 'en' : lang === 1 ? 'es' : lang === 2 ? 'fr' : lang === 3 ? 'pr' : lang === 4 ? 'ru' : lang === 5 ? 'zh' : '';
        localStorage.setItem('lang', languageCode);
        changeLanguage(languageCode);
    };

    const Qa = (lang) => {
        console.log('Clicked on OutSide Lang:', lang);
    };

    const Logout = (index) => {
        if (index == 1) {
            console.log('update_profile');
            navigate('/manage-accounts'); // Redirect to /manage-accounts

        }
        else if (index == 2) {
            navigate('/change-password');
        }
        else if (index === 3) {
            localStorage.clear();
            oktaAuth.signOut();
        }
    };

    const onSelection = (val) => {
        console.log('Selected:', val);
        if (val == 1) {
            console.log('/manage-accounts')
            navigate('/manage-accounts'); // Redirect to /manage-accounts

        } else if (val == 2) {
            navigate('/change-password'); // Redirect to /change-password
        }

    };

    return (
        <div>
            {/* {language &&
                <div style={{ display: 'none' }}>
                    <LanguageDropdown langSelected={language} />
                </div>
            } */}
            <Header
                appLogoProps={{
                    className: !isMobile ? 'pepsico-logo' : '',
                    appName: !isMobile ? 'Identity  Governance Security and Access Management' : '',
                    colorStyle: 'white',
                    logoType: !isMobile ? 'appLogo' : ''
                }}
                menuIconProps={{
                    alt: 'menu',
                    className: '$color-icon-white',
                    icon: 'menu',
                    onClick: function Qa() { },
                    size: 'medium'
                }}
                pepsicoFullLogoProps={{
                    className: 'pepsico-logo',
                    colorStyle: 'white',
                    logoType: 'pepsicoFullLogo'
                }}
                rightToolbar={[
                    {
                        dropdownContent: <NotificationMenu bottomButtonProps={{ iconTrailing: 'arrow_forward', onClick: () => Qa(), size: 'small', text: 'See all Notifications', variant: 'borderless' }} onUpdate={function Qa() { }}
                            options={[
                                { details: 'Long text description with max two lines...', footerText: '2 min ago', isDark: false, link2Props: { iconLeading: '', iconTrailing: 'arrow_forward', label: 'View', onClick: () => Qa(), size: 'small', useAnchor: true }, separator: true, showBadge: false, showDotIcon: false, showLink: true, title: 'Approval Pending ', type: 'row' },
                                { details: 'Long text description with max two lines...', footerText: '2 min ago', isDark: false, link2Props: { iconLeading: '', iconTrailing: 'arrow_forward', label: 'View', onClick: () => Qa(), size: 'small', useAnchor: true }, separator: true, showBadge: false, showDotIcon: false, showLink: true, title: 'Associate PAAS Account', type: 'row' }]
                            }
                            popoverProps={{ direction: 'top-right', showPopover: true, text: 'Notifications' }} style={{ width: '290px' }} />,
                        icon: <Icon alt="alt value" className="$color-icon-white" icon="notifications" size="medium" />,
                        onClickIcon: () => Qa()
                    },
                    {
                        dropdownContent: <NotificationMenu
                            onUpdate={langSelection} options={[
                                {
                                    separator: true, className: 'lang-row', showBadge: false, showDotIcon: false, showLink: true, title: 'English',
                                    type: 'row',
                                    onClick: () => langSelection('English'),
                                    defaultValue: 'English'
                                },
                                {
                                    separator: true, className: 'lang-row', showBadge: false, showDotIcon: false, showLink: true, title: 'Español',
                                    type: 'row',
                                    onClick: () => langSelection('Español'),
                                    defaultValue: 'Español'
                                },
                                {
                                    separator: true, className: 'lang-row', showBadge: false, showDotIcon: false, showLink: true, title: 'Français',
                                    type: 'row',
                                    onClick: () => langSelection('Français'),
                                    defaultValue: 'Français'
                                },
                                {
                                    separator: true, className: 'lang-row', showBadge: false, showDotIcon: false, showLink: true, title: 'Português',
                                    type: 'row',
                                    onClick: () => langSelection('Português'),
                                    defaultValue: 'Português'
                                },
                                {
                                    separator: true, className: 'lang-row', showBadge: false, showDotIcon: false, showLink: true, title: 'Русском',
                                    type: 'row',
                                    onClick: () => langSelection('Русском'),
                                    defaultValue: 'Русском'
                                },
                                {
                                    separator: true, className: 'lang-row', showBadge: false, showDotIcon: false, showLink: true, title: '简体中文',
                                    type: 'row',
                                    onClick: () => langSelection('简体中文'),
                                    defaultValue: '简体中文'
                                }
                            ]} popoverProps={{ direction: 'top-right', showPopover: true, text: 'Languages' }} style={{ width: '290px' }} />,
                        icon: <Icon alt="alt value" className="$color-icon-white" icon="language" size="medium" />,

                    },
                    {
                        dropdownContent:
                            <ProfileMenu
                                onUpdate={Logout}
                                options={[
                                    {
                                        avatarProps: { icon: 'account_circle', onClick: function Qa() { }, showIcon: false },
                                        badgeProps: { color: 'Cyan-500', isRemovable: true, onRemove: function Qa() { }, size: 'large', text: 'Label', type: 'filled' },
                                        deleteIconProps: { icon: 'delete', onClick: function Qa() { }, showIcon: false }, details: '',
                                        downloadIconProps: { icon: 'download', onClick: function Qa() { }, showIcon: false }, isDark: true,
                                        leadingIconProps: { icon: 'account_circle', onClick: function Qa() { }, showIcon: true },
                                        linkProps: { iconLeading: '', iconTrailing: 'chevron_right', label: 'Link', size: 'small', useAnchor: true },
                                        radioButtonProps: { items: ['Radio Label'], label: '', onUpdate: function Qa() { }, reference: 'radio button', size: 'large' },
                                        separator: true, showBadge: false, showDotIcon: false, showLink: false, tertiaryButtonProps: { iconTrailing: 'chevron_right', onClick: function Qa() { }, size: 'small', text: 'Button', variant: 'tertiary' }, title: userName, trailingIconProps: { icon: 'info_outline', onClick: function Qa() { }, showIcon: true }, type: 'row'
                                    },
                                    { avatarProps: { icon: 'account_circle', onClick: function Qa() { }, showIcon: false }, badgeProps: { color: 'Cyan-500', isRemovable: true, onRemove: function Qa() { }, size: 'large', text: 'Label', type: 'filled' }, deleteIconProps: { icon: 'delete', onClick: function Qa() { }, showIcon: false }, details: '', downloadIconProps: { icon: 'download', onClick: function Qa() { }, showIcon: false }, isDark: true, leadingIconProps: { icon: 'settings', onClick: function Qa() { }, showIcon: true }, linkProps: { iconLeading: '', iconTrailing: 'chevron_right', label: 'Link', size: 'small', useAnchor: true }, radioButtonProps: { items: ['Radio Label'], label: '', onUpdate: function Qa() { }, reference: 'radio button', size: 'large' }, separator: true, showBadge: false, showDotIcon: false, showLink: false, tertiaryButtonProps: { iconTrailing: 'chevron_right', onClick: function Qa() { }, size: 'small', text: 'Button', variant: 'tertiary' }, onClick: (val) => onSelection(val), title: 'Update Profile', trailingIconProps: { icon: 'info_outline', onClick: function Qa() { }, showIcon: true }, type: 'row' }, {
                                        avatarProps: { icon: 'account_circle', onClick: function Qa() { }, showIcon: false }, badgeProps: { color: 'Cyan-500', isRemovable: true, onRemove: function Qa() { }, size: 'large', text: 'Label', type: 'filled' }, deleteIconProps: { icon: 'delete', onClick: function Qa() { }, showIcon: false }, details: '', downloadIconProps: { icon: 'download', onClick: function Qa() { }, showIcon: false }, isDark: true, leadingIconProps: { icon: 'double_arrow', onClick: function Qa() { }, showIcon: true }, linkProps: { iconLeading: '', iconTrailing: 'chevron_right', label: 'Link', size: 'small', useAnchor: true }, radioButtonProps: { items: ['Radio Label'], label: '', onUpdate: function Qa() { }, reference: 'radio button', size: 'large' }, separator: true, showBadge: false, showDotIcon: false, showLink: false, tertiaryButtonProps: { iconTrailing: 'chevron_right', onClick: function Qa() { }, size: 'small', text: 'Button', variant: 'tertiary' }, onClick: () => onSelection('change_password'),
                                        title: 'Change Password', trailingIconProps: { icon: 'info_outline', onClick: function Qa() { }, showIcon: true }, type: 'row'
                                    }, {
                                        avatarProps: { icon: 'account_circle', onClick: function Qa() { }, showIcon: false }, badgeProps: { color: 'Cyan-500', isRemovable: true, onRemove: function Qa() { }, size: 'large', text: 'Label', type: 'filled' }, deleteIconProps: { icon: 'delete', onClick: function Qa() { }, showIcon: false }, details: '', downloadIconProps: { icon: 'download', onClick: function Qa() { }, showIcon: false }, isDark: true, leadingIconProps: { icon: 'add_alert', onClick: function Qa() { }, showIcon: true }, linkProps: { iconLeading: '', iconTrailing: 'chevron_right', label: 'Link', size: 'small', useAnchor: true }, radioButtonProps: { items: ['Radio Label'], label: '', onUpdate: function Qa() { }, reference: 'radio button', size: 'large' }, separator: true, showBadge: false, showDotIcon: false, showLink: false, tertiaryButtonProps: { iconTrailing: 'chevron_right', onClick: function Qa() { }, size: 'small', text: 'Button', variant: 'tertiary' }, onClick: () => Logout(),
                                        title: 'Logout', trailingIconProps: { icon: 'info_outline', onClick: function Qa() { }, showIcon: true }, type: 'row'
                                    }]} style={{ width: '330px' }} />,
                        icon: <><Icon alt="alt value"
                            svg={
                                <Avatar
                                    imageUrl="https://randomuser.me/api/portraits/men/1.jpg"
                                    name={userName ? userName : 'User'} size="small" type="image" />} />
                            <Icon alt="alt value" className="$color-icon-white" icon="arrow_drop_down" size="medium" /></>,
                        onClickIcon: function Qa() { }
                    }

                ]}
            />
        </div >
    );
}

export default HeaderView;
