import React, { useEffect } from 'react';
import { Link } from 'pepsico-ds';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();
    useEffect(() => {
        const footerContainer = document.getElementById("footer_container");
        if (window.location.pathname.includes("public")) {
            footerContainer.style.marginLeft = "0";
        }
        else {
            footerContainer.style.marginLeft = "64";
        }
    }, []);
    return (
        <div id="footer_container">
            <div className='col-12'>
                <div className='col-2'>
                    <div className='pep_footer_logo_background'>
                    </div>
                </div>
                <div className='col-4 footer_text_section'>
                    <span className='cardLabel' style={{fontWeight: '400'}} >© 2024 PepsiCo, Inc. All Rights Reserved</span>
                </div>
                <div className='col-6 footer_links_section' >
                    <Link target="_blank" className='footer_links'
                        href="https://sso.mypepsico.com/modernsso/Associate_SSO_Privacy.pdf"
                        iconLeading=""
                        iconTrailing=""
                        label="Link"
                        size="medium"
                        style={{
                            paddingLeft: '0.25rem',
                            paddingRight: '0.25rem'
                        }}
                        useAnchor>Privacy Policy</Link>

                    <Link target="_blank" className='footer_links'
                        href="https://sso.mypepsico.com/modernsso/Associate_SSO_Privacy.pdf"
                        iconLeading=""
                        iconTrailing=""
                        label="Link"
                        size="medium"
                        style={{
                            paddingLeft: '0.25rem',
                            paddingRight: '0.25rem'
                        }}
                        useAnchor>Terms and Conditons</Link>

                    <Link target="_blank" className='footer_links'
                        href="https://sso.mypepsico.com/modernsso/Associate_SSO_Privacy.pdf"
                        iconLeading=""
                        iconTrailing=""
                        label="Link"
                        size="medium"
                        style={{
                            paddingLeft: '0.25rem',
                            paddingRight: '0.25rem'
                        }}
                        useAnchor>Authorized Users</Link>
                </div>
            </div>
        </div>
    )
}

export default Footer;