import React from 'react';
import { Link } from 'react-router-dom';
import { SideBar } from "pepsico-ds";

class SidebarView extends React.Component {
  render() {
    return (
      <div>
        <SideBar
          items={
            [
              {
                icon: 'home',
                label: <Link to="/home">Home</Link>,
                url: '/home'
              },
              {
                icon: 'touch_app',
                label: <Link to="/powerBi">Reporting & Dashboard</Link>,
                url: '/powerBi'
              },
              {
                icon: 'handyman',
                label: 'SSO',
                sublinks: [
                  {
                    label: <Link to="/approvals">Approvals</Link>
                  },
                  {
                    label: <Link to="/rejectedApplications">Rejected Applications</Link>
                  },
                  {
                    label: <Link to="/home">SAML App Onboarding</Link>
                  },
                  {
                    label: <Link to="/">Consumer App-Onbrd</Link>
                  },

                  {
                    label: <Link to="/">Partner App-Onboard</Link>
                  },
                ],
                url: '/'
              },
              {
                icon: 'touch_app',
                label: 'PKI',
                sublinks: [
                  {
                    label: <Link to="/">Request for Certificate</Link>
                  },
                  {
                    label: <Link to="/">Revoke a Certificate</Link>
                  },
                  { label: <Link to="/powerBi">Power BI</Link>, }
                ],
                url: '/'
              },
              {
                icon: 'gamepad',
                label: 'AD',
                sublinks: [
                  {
                    label: <Link to="/">New Azure Application</Link>
                  },
                  {
                    label: <Link to="/">AD Group Mgmt</Link>
                  },],
                url: '/'
              },
              {
                icon: 'widgets',
                label: 'myIdM',
                sublinks: [
                  {
                    label: <Link to="/change-password">Change Password</Link>
                  },
                  {
                    label: <Link to="/manage-accounts">Update Profile</Link>
                  },
                  {
                    label: <Link to="/">Request Access</Link>
                  },],
                url: '/'
              },

            ]
          }
          showCollapsed
          showBottomAction
          navLinkAction={function Qa() { console.log('test') }}
        />
      </div>
    );
  }
}

export default SidebarView;
