// import React, { useState, useEffect } from "react";
// import { models, Report, Embed, service, Page } from "powerbi-client";
// import { PowerBIEmbed } from "powerbi-client-react";

// const PowerBi = () => {

//   const [responseConfig, setResponseConfig] = useState({});

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch("[INSERT FUNCTION URL HERE]", {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//           },
//         });
//         const result = await response.json();
//         setResponseConfig(result);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     fetchData();
//   }, []);

//   return (
//     <div className="App">
//       <header className="App-header">

//         <h1>Power BI Embed: </h1>
//         <PowerBIEmbed
//           embedConfig={{
//             hostname: "https://app.powerbigov.us/",
//             type: "report", // Supported types: report, dashboard, tile, visual and qna
//             id: responseConfig.ReportId,
//             embedUrl: responseConfig.EmbedUrl,
//             accessToken: responseConfig.EmbedToken,
//             tokenType: models.TokenType.Embed,
//             settings: {
//               panes: {
//                 filters: {
//                   expanded: false,
//                   visible: false,
//                 },
//                 pageNavigation: {
//                   visible: false,
//                 },
//               },
//               background: models.BackgroundType.Transparent,
//             },
//           }}
//           eventHandlers={
//             new Map([
//               [
//                 "loaded",
//                 function () {
//                   console.log("Report loaded");
//                 },
//               ],
//               [
//                 "rendered",
//                 function () {
//                   console.log("Report rendered");
//                 },
//               ],
//               [
//                 "error",
//                 function (event) {
//                   console.log(event.detail);
//                 },
//               ],
//             ])
//           }
//           cssClassName={"report-style-class"}
//         />
//       </header>
//     </div>
//   );
// }

// export default PowerBi;

// PowerBIReport.js

// import React, { useState, useEffect } from 'react';
// import { PowerBIEmbed } from 'react-powerbi';
// import { models, Embed } from 'powerbi-client';

// // Mock API call to simulate Power BI embed data
// const fetchMockPowerBIDetails = async () => {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve({
//         embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=dummyReportId',
//         accessToken: 'dummyAccessToken',
//       });
//     }, 1000); // Simulate API delay
//   });
// };

// const PowerBIReport = () => {
//   const [embedConfig, setEmbedConfig] = useState(null);

//   useEffect(() => {
//     // Fetch mock data for Power BI report
//     const getEmbedData = async () => {
//       const data = await fetchMockPowerBIDetails();
//       const config = {
//         type: 'report',
//         embedUrl: data.embedUrl,
//         accessToken: data.accessToken,
//         tokenType: models.TokenType.Embed,
//         settings: {
//           filterPaneEnabled: true,
//           navContentPaneEnabled: true,
//         },
//       };

//       console.log('Embed Config:', config);  // Debug the config
//       setEmbedConfig(config);
//     };

//     getEmbedData();
//   }, []);

//   return (
//     <div>
//       <h1>Power BI Report (Mock Data)</h1>
//       {embedConfig ? (
//         <PowerBIEmbed
//           embedConfig={embedConfig}
//           cssClassName="report-container"
//           getEmbeddedComponent={(embed) => {
//             console.log('Power BI report embedded:', embed);
//           }}
//         />
//       ) : (
//         <p>Loading Power BI Report...</p>
//       )}
//     </div>
//   );
// };

// export default PowerBIReport;


import React, { useEffect } from 'react';

const PowerBIReport = () => {
  // useEffect(() => {
  //   const embedConfig = {
  //     type: 'report',
  //     embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=dummyReportId',
  //     accessToken: 'dummyAccessToken',
  //     tokenType: 1, // Embed token
  //   };

  //   // Power BI embed initialization
  //   const embedContainer = document.getElementById('powerbi-container');
  //   const powerbi = window.powerbi;
  //   powerbi.embed(embedContainer, embedConfig);
  // }, []);

  return (
    <div>
      <iframe title="Reporte de servicio OTCD" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=7d4de3dd-2d81-4056-8d61-d802a7671c25&autoAuth=true&embeddedDemo=true" frameborder="0" allowFullScreen="true"></iframe>
      {/* <h1>Power BI Report</h1>
      <div id="powerbi-container" style={{ height: '500px' }}></div> */}
    </div>
  );
};

export default PowerBIReport;
