import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import Navbar from '../common/navbar';
import SidebarView from '../../common/sidebar';
// import User from '../users/user';
import { Route, Routes, useNavigate } from 'react-router-dom'; // Add useNavigate import
// import AccordionSet from '../accordion/accordion';
// import Request from '../request/request';
import HomeView from './home-view';
// import HeaderView from '../../common/header-public';
import LoginCallBack from '../../auth/LoginCallBack';
import { useOktaAuth } from '@okta/okta-react'; // Add this import statement
import './home.css';
import { set } from 'react-hook-form';
import Settings from '../../common/settings';
import { useMenuContext } from '../../common/menuContext';
import TopMenuBar from '../../common/top_menu_bar';
import HeaderPublic from '../../common/header-public';
import HeaderView from '../../common/header-view';
import ChangePassword from '../../auth/change_password';
import HomeView2 from './home-view2';
const Home = () => {
    const { t } = useTranslation();
    const { oktaAuth } = useOktaAuth();
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const { menuLayout } = useMenuContext();

    // oktaAuth.isAuthenticated().then(data => {
    //     // console.log(data)
    //     if (!data && !window.location.hostname.includes('localhost')) {
    //         navigate("/login");
    //         setIsAuthenticated(true);
    //     }
    //     // else{
    //     //     setIsAuthenticated(true);
    //     // }
    // });
    useEffect(() => {
        const userAuthenticated = JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken?.accessToken;
        if (userAuthenticated || window.location.hostname.includes('localhost')) {
            setIsAuthenticated(true); // Set logged-in status based on token presence
        } else {
            setIsAuthenticated(false);
            navigate("/login");
        }
    }, []);
    return (
        <div>
            <Routes>
                <Route path="/" element={<HomeView />} />
                <Route path="/home" element={<HomeView />} />
                <Route path="home/homev2" element={<HomeView2 />} />               
            </Routes>
            
        </div>

    );
};

export default Home;
