import { Button, Filter, Icon, Search, Tag, Dropdown } from 'pepsico-ds';
import React, { useState, useRef, useEffect } from 'react';
import './workflow.css';
import AgGridComponent from '../../common/ag_grid';
import { Route, Routes, useNavigate } from 'react-router-dom';

const PendingApprovals = ({rowData,searchTerm,filterString,gridToCard,displayType,onDataFromPendingApprovals}) => {
    useEffect(() => {
        if(gridToCard)
        {
            const checkboxes = document.querySelectorAll('.cardCheckBox');
            //       const checkedIndexes = checkedItems.map(item => item.index);
                checkboxes.forEach((checkbox, index) => {
                    if (checkedIndexes.includes(index)) {
                        checkbox.checked = true;
                    } else {
                        checkbox.checked = false;
                    }
                });
                gridToCard=false;    
        }
        setIsGridType(displayType === 'GRID' ? true : false);
        if(displayType === 'GRID' && agGridRef.current)
        {
          setCheckedIndexes(agGridRef.current.getSelectedRowData());
          gridToCard = true;
        }
        else
        {
          gridToCard = false;
        }
        setFilteredItems(rowData);
        if(filterString)
        {
            setFilteredItems(filterRecords(filterString,filteredItems));
        }
        if(searchTerm)
        {
            setFilteredItems(filteredItems.filter((item) => {
                return Object.values(item).some((value) =>
                  value.toString().toLowerCase().includes(searchTerm.toLowerCase())
                );          
            }));
        }
    },[rowData,searchTerm,filterString,displayType]);

    const sendDataToApprovals = (data) => {
      onDataFromPendingApprovals(data);
    };
    const [columnDefs] = useState([
        { headerName: "Request #", field: "requestNumber" },
        { headerName: "Request Type", field: "requestType" },
        { headerName: "Requester", field: "requester" },
        { headerName: "Requested Date", field: "requestedDate" },
        { headerName: "Due Date", field: "dueDate" },
        { headerName: "Actions", field: "actions" }
    ]);
    const agGridRef = useRef();
    const navigate = useNavigate();
    const [selectedCount, setSelectedCount] = useState(0);
    const [checkedIndexes, setCheckedIndexes] = useState([]);
    const rowSelection = {
        mode: 'multiRow',
        headerCheckbox: true,
    };

    const [isGridType, setIsGridType] = React.useState(true);
    const [filteredItems, setFilteredItems] = useState([]);

   const getDataFromGrid = (nodes) =>
    {
      sendDataToApprovals({selectedCount: nodes.length});
   //   setSelectedCount(nodes.length);
    };

    const displaySelectedRows = () => {
        if (agGridRef.current) {
            agGridRef.current.getSelectedRowData();
        }
      };

      const onSelectAll = () => {
        if (agGridRef.current) {
            agGridRef.current.selectAllCheckBoxes();
    //        setSelectedCount(agGridRef.current.countSelected());
            sendDataToApprovals({selectedCount: agGridRef.current.countSelected()});

        }
        else
        {
            const checkboxes = document.querySelectorAll('.cardCheckBox');
            checkboxes.forEach((checkbox,index) => {
              checkbox.checked = true;
                 setCheckedIndexes((prevIndexes) => {
                   return [...prevIndexes, index];
                });            
            });
         //   setSelectedCount(checkboxes.length);
            sendDataToApprovals({selectedCount: checkboxes.length});

        }
      };

      const onClearAll = () => {
        setSelectedCount(0);
        searchTerm='';
        if (agGridRef.current) {
            agGridRef.current.clearAllCheckBoxes();
            agGridRef.current.clearAllFilters();
         }
        else
        {
            const checkboxes = document.querySelectorAll('.cardCheckBox');
            checkboxes.forEach((checkbox) => {
              checkbox.checked = false;
            });
            setCheckedIndexes([]);
        }
      };
    
      const onCheckboxChange = (event,index) => {
        setCheckedIndexes((prevIndexes) => {
            if (event.target.checked) {
                return [...prevIndexes, index];
            } else {
                return prevIndexes.filter((i) => i !== index);
             }
        });
    
        const checkboxes = document.querySelectorAll('.cardCheckBox');
        const checkedCount = Array.from(checkboxes).filter(checkbox => checkbox.checked).length;
       // setSelectedCount(checkedCount);
        sendDataToApprovals({selectedCount: checkedCount});
      };
    
    const startWorkflow = () => {
        navigate('/workflow');
    }

    const getToday = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return today;
      };
      
      const getTomorrow = () => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);
        return tomorrow;
      };
      
      const getStartOfWeek = () => {
        const today = new Date();
        const firstDay = today.getDate() - today.getDay();
        const startOfWeek = new Date(today.setDate(firstDay));
        startOfWeek.setHours(0, 0, 0, 0);
        return startOfWeek;
      };
      
      const getStartOfMonth = () => {
        const today = new Date();
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        startOfMonth.setHours(0, 0, 0, 0);
        return startOfMonth;
      };

      const filterByDate = (records, startDate, endDate) => {
        return records.filter(record => {
          const dueDate = new Date(record.dueDate);
          return dueDate >= startDate && dueDate < endDate;
        });
      };
      
      const filterToday = records => {
        const today = getToday();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        return filterByDate(records, today, tomorrow);
      };
      
      const filterTomorrow = records => {
        const tomorrow = getTomorrow();
        const dayAfter = getTomorrow();
        dayAfter.setDate(dayAfter.getDate() + 1);
        dayAfter.setHours(0, 0, 0, 0);
        return filterByDate(records, tomorrow, dayAfter);
      };
      
      const filterThisWeek = records => {
        const startOfWeek = getStartOfWeek();
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 7);
        return filterByDate(records, startOfWeek, endOfWeek);
      };
      
      const filterNextWeek = records => {
        const startOfNextWeek = getStartOfWeek();
        startOfNextWeek.setDate(startOfNextWeek.getDate() + 8);
        const endOfNextWeek = getStartOfWeek();
        endOfNextWeek.setDate(startOfNextWeek.getDate() + 7);
        return filterByDate(records, startOfNextWeek, endOfNextWeek);
      };
      
      const filterThisMonth = records => {
        const startOfMonth = getStartOfMonth();
        const startOfNextMonth = new Date(startOfMonth);
        startOfNextMonth.setMonth(startOfMonth.getMonth() + 1);
        return filterByDate(records, startOfMonth, startOfNextMonth);
      };
      
      const filterNextMonth = records => {
        const startOfMonth = getStartOfMonth();
        const startOfNextMonth = new Date(startOfMonth);
        startOfNextMonth.setMonth(startOfMonth.getMonth() + 1);
        const startOfNextTwoMonth = new Date(startOfNextMonth);
        startOfNextTwoMonth.setMonth(startOfMonth.getMonth() + 2);
        return filterByDate(records, startOfNextMonth, startOfNextTwoMonth);
      };      

      const filterRecords = (dateStr, records ) => {
         console.log(dateStr) ;
        switch (dateStr.toLowerCase()) {
          case 'today':
            return filterToday(records);
          case 'tomorrow':
            return filterTomorrow(records);
          case 'thisweek':
            return filterThisWeek(records);
          case 'nextweek':
            return filterNextWeek(records);
          case 'thismonth':
            return filterThisMonth(records);
          case 'nextmonth':
            return filterNextMonth(records);
          default:
            return records; // Return all records if no match
        }
      };
    return (
        <div id="pending_Approvals">
            {isGridType && (
                <div className='d-flex'>
                    <div className='cards-container'>
                        {filteredItems.map((card, index) => (
                            <div className='card approvalCard' key={index}>
                                <div className='card-content'>
                                    <div className='d-flex w-100 justify-content-between mt-2'>
                                        <div className='approvalHeader'  onClick={()=> startWorkflow()}>{card.requestType}</div>
                                        <Icon icon="more_vert" />
                                    </div>
                                    <div className='d-flex w-100 justify-content-between mt-2'>
                                        <span>Req # {card.requestNumber}</span>
                                        <input className="cardCheckBox" type="checkbox"  onClick={(event) => onCheckboxChange(event,index)} style={{ marginLeft: '10px' }} />
                                    </div>
                                    <div className='mt-2'>
                                        <Tag
                                            color="Orange-400"
                                            iconLeading="warning"
                                            onRemove={function Qa() { }}
                                            size="large"
                                            text="Label"
                                            type="filled"
                                        />
                                    </div>
                                    <div className='d-flex w-100 mt-2' style={{ gap: '12px' }}>
                                        <Icon icon='calendar_today' className='link' />
                                        <span className='detailsValue'>Last Update</span>
                                        <span className='detailsValue'>{card.requestedDate}</span>
                                    </div>
                                    <div className='d-flex w-100 justify-content-between mt-2'>
                                        <div>
                                            <div className='detailsLabel'>Delegated From</div>
                                            <div className='detailsValue'>{card.requester}</div>
                                        </div>
                                        <div style={{ border: 'solid 1px #e5e5e5' }}></div>
                                        <div>
                                            <div className='detailsLabel'>Initiated By</div>
                                            <div className='detailsValue'>10/10/2021</div>
                                        </div>
                                    </div>
                                    <div className='d-flex w-100 justify-content-end mt-4'>
                                        <Button size="small" text="Reject" variant="primaryInverse" iconLeading='close' />
                                        <Button size="small" text="Approve" variant="secondary" iconLeading='check' />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {!isGridType && (
                <div className='table-container'>

                    <AgGridComponent
                        columnDefs={columnDefs}
                        rowData={filteredItems}
                        rowSelection={rowSelection}
                        ref={agGridRef}
                        checkedIndexes={checkedIndexes}
                        sendDataToApprovals = {getDataFromGrid}
                    />
                </div>
            )}
        </div>
    )
};
export default PendingApprovals;