import { Accordion, AccordionItem } from 'pepsico-ds';
import { useEffect, useState } from 'react';
const ForgotFooter = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <div>
            <hr className='custom-hr' />
            {isMobile ? (
                <Accordion>
                    <AccordionItem label='FOR AUTHORIZED USERS ONLY' subTitle="Disclaimer">
                        <span className='forgot_pwd_footer_info'>FOR AUTHORIZED USERS ONLY. This is a proprietary system and NOT for public or personal use. All work products, communications (including but not limited to e-mail), files, data or information created, inputted or accessed on this system (including but not limited to PepsiCOLLABORATION) are and shall become the sole property of the company. Where permitted by applicable law and PepsiCo policies, standards and procedures (including the PepsiCo Acceptable Use Policy), PepsiCo may monitor, review, record or otherwise process files, messages, communications or other information stored, created, sent, received, viewed or accessed on or using PepsiCo systems, and computing, communication, or information assets. By logging onto this system and ongoing use, user acknowledges having read and being in agreement with these terms and, where permitted by law, consents to such monitoring and recording, along with all applicable company policies including, but not limited to, the PepsiCo Information Security Policies and Standards. SUBJECT TO APPLICABLE LAW, USE OF THIS SYSTEM WITHOUT PROPER AUTHORIZATION OR CONTRARY TO COMPANY POLICIES MAY SUBJECT THE USER TO DISCIPLINE, UP TO AND INCLUDING TERMINATION FOR CAUSE, AND POTENTIAL INDIVIDUAL LIABILITY FOR CIVIL AND CRIMINAL PENALTIES.</span>
                    </AccordionItem>
                </Accordion>
            ) : (
                <div style={{ padding: '0px 8px', textAlign: 'start' }}>
                    <span className='forgot_pwd_footer_info'>FOR AUTHORIZED USERS ONLY. This is a proprietary system and NOT for public or personal use. All work products, communications (including but not limited to e-mail), files, data or information created, inputted or accessed on this system (including but not limited to PepsiCOLLABORATION) are and shall become the sole property of the company. Where permitted by applicable law and PepsiCo policies, standards and procedures (including the PepsiCo Acceptable Use Policy), PepsiCo may monitor, review, record or otherwise process files, messages, communications or other information stored, created, sent, received, viewed or accessed on or using PepsiCo systems, and computing, communication, or information assets. By logging onto this system and ongoing use, user acknowledges having read and being in agreement with these terms and, where permitted by law, consents to such monitoring and recording, along with all applicable company policies including, but not limited to, the PepsiCo Information Security Policies and Standards. SUBJECT TO APPLICABLE LAW, USE OF THIS SYSTEM WITHOUT PROPER AUTHORIZATION OR CONTRARY TO COMPANY POLICIES MAY SUBJECT THE USER TO DISCIPLINE, UP TO AND INCLUDING TERMINATION FOR CAUSE, AND POTENTIAL INDIVIDUAL LIABILITY FOR CIVIL AND CRIMINAL PENALTIES.</span>
                </div>
            )}
        </div>
    );
}
export default ForgotFooter;