import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    home_welcome: "View request pending/approved",
                    language: 'English',
                    view_page_in: "View page in",
                    label_user_id: 'User ID',
                    forgot_password_reset: 'Forgot password',
                    quick_links: 'Quick Links',
                    password_reset: 'Password Reset',
                    passwordUpdateRequired: "Password update required by Nov 21",
                    passwordExpirationNotice: "Your current password will expire, to avoid service interruptions, please update your password before this date.",
                    createForm: "Create Form",
                    changePassword: "Change Password",
                    iamApplications: "IAM Applications",
                    userActivity: "User Activity",
                    tasksCompleted: "Tasks Completed",
                    approvals: "approvals",
                    quick_links: "Quick Links",
                    viewRequest: "View request pending/approved",
                    profileUpdate: "Profile Update",
                    messageBoard: "Message Board",
                    iamAnnouncements: "Announcements",
                    needHelp: "Need help? Talk to an expert",
                    validGpid: 'Please enter a valid GPID',
                    invalidGpid: 'The user id is invalid or does not exist',
                },
            },
            zh: {
                translation: {
                    home_welcome: "Bienvenido a la página principal",
                    language: '简体中文',
                    view_page_in: "在中查看页面",
                    label_user_id: '用户 ID - 电子邮件地址或全局人员 ID (GPID)',
                    forgot_password_reset: '忘记密码重置：输入以下内容以识别自己',
                    password_reset: '密码重置',
                    passwordUpdateRequired: "密码更新要求在11月21日之前完成",
                    passwordExpirationNotice: "您的当前密码将过期，为避免服务中断，请在此日期之前更新您的密码。",
                    changePassword: "更改密码",
                    iamApplications: "IAM应用程序",
                    userActivity: "用户活动",
                    tasksCompleted: "已完成任务",
                    approvals: "批准",
                    quick_links: "快速链接",
                    viewRequest: "查看待处理/已批准的请求",
                    profileUpdate: "更新资料",
                    messageBoard: "留言板",
                    iamAnnouncements: "公告",
                    needHelp: "需要帮助？联系专家",
                    validGpid: '请输入有效的GPID',
                    invalidGpid: '用户ID无效或不存在。',
                },
            },
            ru: {
                translation: {
                    home_welcome: "Bienvenido a la página principal",
                    language: 'Русском',
                    view_page_in: "Посмотреть страницу в",
                    label_user_id: '用户 ID - 电子邮件地址或全局人员 ID (GPID)',
                    forgot_password_reset: 'Сброс пароля от забытого: введите следующее, чтобы идентифицировать себя',
                    passwordUpdateRequired: "Обновление пароля требуется до 21 ноября",
                    passwordExpirationNotice: "Ваш текущий пароль истекает, чтобы избежать перебоев в обслуживании, обновите свой пароль до этой даты.",
                    changePassword: "Изменить пароль",
                    iamApplications: "Приложения IAM",
                    userActivity: "Активность пользователя",
                    tasksCompleted: "Завершенные задачи",
                    approvals: "Утверждения",
                    quick_links: "Быстрые ссылки",
                    viewRequest: "Просмотр ожидающих/утвержденных запросов",
                    profileUpdate: "Обновление профиля",
                    messageBoard: "Доска объявлений",
                    iamAnnouncements: "Объявления",
                    needHelp: "Нужна помощь? Обратитесь к эксперту",
                    validGpid: 'Пожалуйста, введите действительный GPID',
                    invalidGpid: 'Идентификатор пользователя недействителен или не существует',
                },
            },
            es: {
                translation: {
                    home_welcome: "Bienvenido a la página principal",
                    language: 'Español',
                    view_page_in: "Ver página en",
                    label_user_id: 'ID de usuario: dirección de correo electrónico o ID de persona global (GPID)',
                    forgot_password_reset: 'Restablecimiento de contraseña olvidada: ingrese lo siguiente para identificarse',
                    passwordUpdateRequired: "Actualización de contraseña requerida para el 21 de noviembre",
                    passwordExpirationNotice: "Su contraseña actual expirará, para evitar interrupciones en el servicio, actualice su contraseña antes de esta fecha.",
                    changePassword: "Cambiar contraseña",
                    iamApplications: "Aplicaciones IAM",
                    userActivity: "Actividad del usuario",
                    tasksCompleted: "Tareas completadas",
                    approvals: "Aprobaciones",
                    quick_links: "Enlaces rápidos",
                    viewRequest: "Ver solicitud pendiente/aprobada",
                    profileUpdate: "Actualización de perfil",
                    messageBoard: "Tablón de mensajes",
                    iamAnnouncements: "Anuncios",
                    needHelp: "¿Necesitas ayuda? Habla con un experto",
                    validGpid: 'Por favor, introduzca un GPID válido',
                    invalidGpid: 'El ID de usuario es inválido o no existe',
                },
            },
            pr: {
                translation: {
                    home_welcome: "Visualizar página em",
                    language: 'Português',
                    view_page_in: "Visualizar página em",
                    label_user_id: 'ID do usuário - endereço de e-mail ou ID de pessoa global (GPID)',
                    forgot_password_reset: 'Forgot Password Reset: Preencha o campo abaixo para identificar-se',
                    passwordUpdateRequired: "Atualização de senha necessária até 21 de novembro",
                    passwordExpirationNotice: "Sua senha atual expirará, para evitar interrupções no serviço, atualize sua senha antes desta data.",
                    changePassword: "Alterar senha",
                    iamApplications: "Aplicativos IAM",
                    userActivity: "Atividade do usuário",
                    tasksCompleted: "Tarefas concluídas",
                    approvals: "Aprovações",
                    quick_links: "Links rápidos",
                    viewRequest: "Ver solicitação pendente/aprovada",
                    profileUpdate: "Atualização de perfil",
                    messageBoard: "Quadro de mensagens",
                    iamAnnouncements: "Anúncios",
                    needHelp: "Precisa de ajuda? Fale com um especialista",
                    validGpid: 'Por favor, insira um GPID válido',
                    invalidGpid: 'O ID do usuário é inválido ou não existe',
                },
            },
            fr: {
                translation: {
                    home_welcome: "Bienvenue sur la page d'accueil",
                    language: 'Français',
                    view_page_in: "Voir cette page en",
                    label_user_id: 'ID utilisateur - Adresse e-mail ou ID de personne globale (GPID)',
                    forgot_password_reset: 'Réinitialisation du mot de passe oublié : veuillez entrer les informations suivantes pour vous identifier',
                    passwordUpdateRequired: "Mise à jour du mot de passe requise avant le 21 novembre",
                    passwordExpirationNotice: "Votre mot de passe actuel expirera, pour éviter des interruptions de service, veuillez mettre à jour votre mot de passe avant cette date.",
                    changePassword: "Changer le mot de passe",
                    iamApplications: "Applications IAM",
                    userActivity: "Activité utilisateur",
                    tasksCompleted: "Tâches terminées",
                    approvals: "Approbations",
                    quick_links: "Liens rapides",
                    viewRequest: "Voir la demande en attente/approuvée",
                    profileUpdate: "Mise à jour du profil",
                    messageBoard: "Tableau des messages",
                    iamAnnouncements: "Annonces",
                    needHelp: "Besoin d'aide ? Parlez à un expert",
                    validGpid: 'Veuillez entrer un GPID valide',
                    invalidGpid: "L'identifiant utilisateur est invalide ou n'existe pas",
                },
            },
        },
        lng: 'en', // Default language
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
