import {
  formEngineRsuiteCssLoader,
  ltrCssLoader,
  rsErrorMessage,
  RsLocalizationWrapper,
  rSuiteComponents,
  rtlCssLoader
} from '@react-form-builder/components-rsuite'
import {BiDi, createView, FormViewer, IFormViewer} from '@react-form-builder/core'
import React, { useRef } from 'react'
import * as SampleForm from '../json_forms/IntialForm.json'
// import LanguageDropdown from '../common/languageDropdown';
const viewerComponents = rSuiteComponents.map(c => c.build().model)
viewerComponents.push(rsErrorMessage.build().model)

const view = createView(viewerComponents)
  .withViewerWrapper(RsLocalizationWrapper)
  .withCssLoader(BiDi.LTR, ltrCssLoader)
  .withCssLoader(BiDi.RTL, rtlCssLoader)
  .withCssLoader('common', formEngineRsuiteCssLoader)

const getForm = (_ = '' ) => JSON.stringify(SampleForm)


  
function getCircularReplacer() {
  const seen = new WeakSet();
  return (key, value) => {
      if (typeof value === "object" && value !== null) {
          if (seen.has(value)) {
              return;
          }
          seen.add(value);
      }
      return value;
  };
}

function FormTemplate() {
  
  const ref = useRef(null);
  const setRef = (viewer) => {
    if (viewer) {
      ref.current = viewer
      const formJSON = JSON.stringify(ref.current.formData, getCircularReplacer())
      console.log('formJSON ', ref.current)
    }
  }

  return <div style={{margin: 20}}>
    <FormViewer  onFormDataChange={({data, errors}) => {
        const formJSON = JSON.stringify(data)
        console.log('onFormDataChange', formJSON)
      }} viewerRef={setRef} licenseKey={'PepsiCo-eyJsaWNlbnNlRW5kRGF0ZSI6IjAxLjE4LjIwMjUiLCJyZWxlYXNlRXhwaXJ5IjoiMDEuMTguMjAyNSIsImNvbXBhbnlOYW1lIjoiUGVwc2lDbyIsInJlc3RyaWN0aW9ucyI6eyJTdHJpY3RDaGVjayI6dHJ1ZX19:VpGspVoUev5AOH11Z/a5VtDoAreoAv7H7oq4TffDbxCRZA2DzXOd5tisua+arArbZeMLusDeZZyKwWXR5cf/YV4cFNLr84V/yuSHJOIl+wNSussCQnQnU71DDKbgRp2C'} view={view} formName="SampleForm" getForm={getForm}/>
  </div>
}

export default FormTemplate;
