import React, { useEffect, useState } from 'react';
import { TextInput, Checkbox, Button, Dropdown } from 'pepsico-ds';
import { useTranslation } from 'react-i18next';
import './auth.css';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useOktaAuth } from "@okta/okta-react"
import { useDispatch } from 'react-redux';
import ForgotPassword from './forgot-password';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'
import { useNavigate } from "react-router";
import { ReactComponent as AddQuestion } from '../assets/registration/add_question.svg';
import ForgotFooter from '../common/forgot_footer';

function Registration() {
    const { REACT_APP_API_ENDPOINT, application_key } = process.env;
    const dispatch = useDispatch();
    const { authState } = useOktaAuth();
    const data = JSON.parse(localStorage.getItem('okta-token-storage'));
    let roles = data?.accessToken?.claims?.pepapppartnerroles?.map(item => item);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        dispatch({ type: 'USER_LOGIN', payload: { authState: authState, isAuthenticated: authState?.isAuthenticated, roles: roles } })
    })
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState('');
    const [gpidErrorMessage, setGpidErrorMessage] = useState('');
    const [stepNumber, setStepNumber] = useState(0);
    const [validId, setValidId] = useState(false);
    const [isRegistration, setIsRegistration] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [userIdValidated, setUserIdValidated] = useState(false);
    const [preferredLanguage, setPreferredLanguage] = useState('');
    const [minLength, setMinLength] = useState(false);
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(eyeOff);
    const [res, setres] = useState({});
    const [passwordSuccess, setPasswordSuccess] = useState(false);
    const [endpointAccounts, setEndpointAccounts] = useState(['myidM', 'IDX', 'PKI']);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [isTyped, setIsTyped] = useState(false);
    const [useridValid, setUseridValid] = useState(false);
    const [userType, setUserType] = useState('Type1');
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [dateErrors, setDateErrors] = useState({});
    const [dateErrorType, setDateErrorType] = useState('');
    const [passwordHints, setPasswordHints] = useState({
        minLength: false,
        hasLowercase: false,
        hasUppercase: false,
        hasSpecialChar: false,
        hasTwoNumbers: false,
        noSequentialChars: true,
        noForbiddenKeywords: true,
        noRepeatingChars: true,
        notStartWithNumber: true,
        notEndWithNumber: true,
    });
    let availableQuestions = [];

    const addSelectedQuestions = (selectedQn, secQn) => {
        setSelectedQuestions([...selectedQuestions, { id: secQn.id, qn: selectedQn }]);
    };
    const setProgress = (step) => {
        if (stepNumber === 1 && step < 0) {
            setStepNumber(count => count - 1);
            setIsRegistration(false);
        }
        else if (step > 0) {
            setStepNumber(count => count + 1);
        }
        else if (step < 0) {
            // stepNumber = stepNumber - 1;
            setStepNumber(count => count - 1);
        }
    }
    const handleToggle = () => {
        if (type === 'password') {
            setIcon(eye)
            setType('text')
        } else {
            setIcon(eyeOff)
            setType('password')
        }
    }
    const navigate = useNavigate(); // Initialize the navigation hook

    const handleLoginClick = () => {
        navigate('/login'); // Redirect to /login
    };

    const [secQns, setSecQns] = useState([]);

    const [secQnsTxt, setSecQnsTxt] = useState([]);

    const addMandatoryQuestions = () => {
        if (secQns.length >= 3) {
            return true;
        }
        else {
            for (let i = secQns.length; i <= 3; i++) {
                addSecQn();
            }
        }
        return true;
    };

    const addSecQn = () => {
        let index = secQns.length + 1
        setSecQns([...secQns, { id: index, ddHeader: 'Security question ' + index, lbl: 'Security question ' + index, question: 'question' + index, answer: 'answer' + index, answerlbl: 'Answer ' + index }]);
    };

    const addSecQnTxt = () => {
        let index = secQnsTxt.length + 4
        setSecQnsTxt([...secQnsTxt, { id: index, ddHeader: 'Security question ' + index, lbl: 'Security question ' + index, question: 'question' + index, answer: 'answer' + index, answerlbl: 'Answer ' + index }]);
    };

    const removeSecQn = () => {
        setSecQns(secQns.slice(0, -1));
    };

    const removeSecQnTxt = () => {
        setSecQnsTxt(secQnsTxt.slice(0, -1));
    };

    const [securityQnList, setSecurityQnList] = useState([]);

    const getHintColor = (conditionMet) => {
        // If user hasn't typed yet, default to gray, otherwise red or green based on condition
        return isTyped ? (conditionMet ? 'green' : 'red') : '#9e9e9e';
    };

    const powdMessageValidation = (password) => {
        setIsTyped(true);
        // Base regex for MUST conditions
        const basePattern = /^(?=.*[a-zA-Z]{4,})(?=.*\d{2,})(?=.*[!^*()_|~`{}.#\[\]:;'"<>?,])(?=.{8,14}$)/;

        // Check length, letters, numbers, and special characters
        const isBaseValid = basePattern.test(password);

        // Additional checks
        const hasLowercase = /[a-z]/.test(password);
        const hasUppercase = /[A-Z]/.test(password);
        const hasSpecialChar = /[!^*()_|~`{}.#\[\]:;'"<>?,]/.test(password);
        const hasTwoNumbers = /\d.*\d/.test(password);

        // Forbidden keywords
        const forbiddenKeywords = ["spring", "summer", "fall", "winter", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", "PepsiCo", "Pepsi"];
        const noForbiddenKeywords = !forbiddenKeywords.some(keyword => password.toLowerCase().includes(keyword.toLowerCase()));

        // Sequential characters check (abc, 123)
        const isSequential = (str) => {
            for (let i = 0; i < str.length - 2; i++) {
                if (str.charCodeAt(i + 1) === str.charCodeAt(i) + 1 && str.charCodeAt(i + 2) === str.charCodeAt(i) + 2) {
                    return true;
                }
            }
            return false;
        };
        const noSequentialChars = !isSequential(password);

        // Repeating characters check (no more than two repeating characters)
        const noRepeatingChars = !/(.)\1{2,}/i.test(password);

        // Check if it starts or ends with a number
        const notStartWithNumber = !/^\d/.test(password);
        const notEndWithNumber = !/\d$/.test(password);

        // Update validation state
        setPasswordHints({
            minLength: isBaseValid,
            hasLowercase: hasLowercase,
            hasUppercase: hasUppercase,
            hasSpecialChar: hasSpecialChar,
            hasTwoNumbers: hasTwoNumbers,
            noSequentialChars: noSequentialChars,
            noForbiddenKeywords: noForbiddenKeywords,
            noRepeatingChars: noRepeatingChars,
            notStartWithNumber: notStartWithNumber,
            notEndWithNumber: notEndWithNumber,
        });
    };
    const onConfirmation = (value) => {
        setIsConfirmed(value);
    }
    const handleChange = (event) => {
        const onlyNumbers = event.target.value.replace(/[^0-9]/g, ''); // Regex to remove non-numeric characters
        setInputValue(onlyNumbers);
        setValidId(true);
    }

    const [selectedOptions, setSelectedOptions] = useState({});
    const { register, handleSubmit, setError, formState: { errors, isValid }, watch } = useForm();

    const { handleSubmit: handleSubmitUserId, formState: { errors: errors2 }, watch: watch2 } = useForm()

    const onSubmit = (data) => {
        console.log(data);
        console.log(preferredLanguage);

        setIsRegistration(true);
        setProgress(1);
    }

    const onFinalSubmit = (data) => {
        console.log(data);
        setPasswordSuccess(true);
        setProgress(1);
    }

    const onSecurityQstnsSubmit = (e) => {
        saveSecurityQuestions(e)
    }

    const validateUserDetails = (e) => {
        console.log(e);
    }

    const saveSecurityQuestions = (formData) => {
        let body = {};
        console.log(selectedQuestions);
        selectedQuestions.map((item) =>
            formData['question' + item.id] = item.qn
        );
        for (let i = 1; i < 6; i++) {
            console.log(formData['question' + i]);
            console.log(formData['answer' + i]);
        }
        body[availableQuestions[0]] = formData['question1'];
        body[availableQuestions[1]] = formData['question2'];
        setProgress(1);
    }
    const userTypes = [
        {
            displayText: 'Type1',
            id: '1',
            isBadge: false
        },
        {
            displayText: 'Type2',
            id: '2',
            isBadge: false
        },
        {
            displayText: 'Type3',
            id: '3',
            isBadge: false
        }]

    const password = watch("password");
    const securityQuestions = [
        {
            displayText: 'Question1',
            id: '1',
            isBadge: false
        },
        {
            displayText: 'Question2',
            id: '2',
            isBadge: false
        },
        {
            displayText: 'Question3',
            id: '3',
            isBadge: false
        },
        {
            displayText: 'Question4',
            id: '4',
            isBadge: false
        },
        {
            displayText: 'Question5',
            id: '5',
            isBadge: false
        }
    ]


    const validateDate = (date) => {
        // Regular expression for mm/dd/yyyy format
        const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
        return regex.test(date);
    };

    const handleDateChange = (e, dateType) => {
        const { value } = e.target;
        // setDate(value);
        const errorsCopy = { ...dateErrors };

        if (validateDate(value)) {
            delete errorsCopy[dateType];
        } else {
            errorsCopy[dateType] = 'Invalid date format. Please use mm/dd/yyyy.';
        }
        setDateErrors(errorsCopy);
    };

    const onSubmitUserId = (formData) => {
        validateUserId();
    };

    const goPrevious = () => {
        setUserIdValidated(false);
        setUserType('');
    }

    const validateUserId = () => {
        // clearErrors("getChalFac");
        let retried = false;
        axios.post(`${REACT_APP_API_ENDPOINT}/user/validate`, {
            "gpid": inputValue,
        }).then(response => {
            if (response?.data?.userType) {
                setUserType(response?.data?.userType);
                setUserIdValidated(true);

            }
            if (response?.data?.status === 'Failed') {
                setError("validateUser", {
                    type: "manual",
                    message: response?.data?.errorMessage
                });
            }
            setres(response.data)
            setUseridValid(true);
        })
            .then(data => console.log({ data }))
            .catch(error => {
                // Handle error (e.g., display error message)
                const originalConfig = error.config;
                console.error('Error fetching data:', error?.response?.data?.message);
                // setGpidErrorMessage(error?.response?.data?.message);
                if (error?.response?.status === 403 && !retried) {
                    retried = true;
                    validateUserId();
                }
                else {
                    setError("validateUser", {
                        type: "manual",
                        message: error?.message
                    });
                }
                // return { "gpid": "22221108", "secQuestions": ["What was your first pet's name?", "What was your high school mascot?"], "mobile": "+917604877905" }
            });
    }

    let addFormFields = () => {
        setSecurityQnList([...securityQnList, {}])
    }

    let removeFormFields = (i) => {
        let newSecurityQnList = [...securityQnList];
        newSecurityQnList.splice(i, 1);
        setSecurityQnList(newSecurityQnList)
    }
    const getObject = (gpid) => {
        // return object.filter((obj) => obj.gpid === gpid);
    }
    const childList = [
        {
            displayText: 'English',
            id: '1',
            isBadge: false
        },
        {
            displayText: 'French',
            id: '2',
            isBadge: false
        },
        {
            displayText: 'Chinese',
            id: '3',
            isBadge: false
        },
        {
            displayText: 'Arabic',
            id: '4',
            isBadge: false
        },
        {
            displayText: 'Portugese',
            id: '5',
            isBadge: false
        }
    ];

    return (
        <div>
            <div className='pep_registration_logo_background'></div>

            <div id='registration_container'>

                {/* <HeaderPublic /> */}
                <div>
                    {!isRegistration && !userIdValidated && (
                        <div className="card card-layout">
                            <div className='card-content'>
                                <div className='idm_logo_background'></div>
                                <div>
                                    <h2>Welcome to myidM</h2>

                                </div>
                                {/* <div className='info-container'>                                    
                                    <p>First Time Login - Registration: Please enter your Identity</p>
                                </div> */}
                                <hr className='custom-hr' />
                                <form onSubmit={handleSubmitUserId(onSubmitUserId)} className='w-full'>
                                    <div className={isMobile ? 'w-full m-auto' : 'w-1-2 m-auto'}>
                                        <div >
                                            <TextInput type="text" required showLabelIcon label={t('label_user_id')} value={inputValue} onChange={handleChange} />
                                            {errors.validateUser && <div className='fieldError'>{errors.validateUser.message}</div>}
                                        </div>
                                        <Button className='full-button' size="medium" text='Continue' type='submit' />
                                    </div>
                                </form>
                                <ForgotFooter />
                            </div>
                        </div>
                    )}
                    {!isRegistration && userIdValidated && (userType === 'CONTRACTOR' || userType === 'THIRD PARTY') && (
                        <div className="card card-layout">
                            <div className='card-content'>
                                <div className='idm_logo_background'></div>
                                <div>
                                    <h2>Welcome to myidM</h2>

                                </div>
                                {/* <div className='info-container'>
                                    
                                    <p>First Time Login - Registration: Please enter your Identity</p>
                                </div> */}
                                <hr className='custom-hr' />
                                <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
                                    <div className={isMobile ? 'w-full m-auto' : 'w-1-2 m-auto'}>
                                        <div className={isMobile ? 'w-full m-auto' : 'w-1-2 m-auto'}>
                                            <TextInput type="text" readOnly showLabelIcon label={t('label_user_id')} value={inputValue} onChange={handleChange} />
                                            {errors.getChalFac && <div className='fieldError'>{errors.getChalFac.message}</div>}
                                        </div>
                                        <div className={isMobile ? 'w-full m-auto' : 'w-1-2 m-auto'}>
                                            <TextInput type="text" value={this} {...register('password', { required: 'Password is required' })} info={t('Help: You can find in an email with the User ID')} required label={t('Temporary Password')} />
                                        </div>
                                        <div className={isMobile ? 'w-full m-auto' : 'w-1-2 m-auto'}>
                                            <Dropdown
                                                childList={childList}
                                                dropdownHeader="Preferred Language"
                                                feedbackType="info"
                                                helperText="Help: This is a hint"
                                                isRequired
                                                label={t('Preferred Language')}
                                                placeholder={t('Default to region')}
                                                selection="single"
                                                setSelectedValue={(val) => setPreferredLanguage(val[0].displayText)}
                                                size="large"
                                            />
                                        </div>
                                        <Button className='full-button' size="medium" text='Continue' type='submit' />
                                    </div>
                                </form>
                                <ForgotFooter />
                            </div>
                        </div>
                    )}
                    {!isRegistration && userIdValidated && userType === 'EMPLOYEE' && (
                        <div className="card card-layout">
                            <div className='card-content'>
                                <div style={{ display: 'flex' }}>
                                    <Button size="small" text="Back" iconLeading='arrow_back' className="link" style={{ textAlign: 'left' }} variant="tertiary" onClick={goPrevious} />
                                </div>
                                <div className='idm_logo_background'></div>
                                <div>
                                    <h2>Welcome to myidM</h2>
                                </div>
                                {/* <div className='info-container'>                                   
                                    <p>First Time Login - Registration: Please enter your Identity</p>
                                </div> */}
                                <hr className='custom-hr' />
                                <form onSubmit={handleSubmit(onSubmit)} className='w-full'>

                                    <div className={isMobile ? 'w-full m-auto' : 'w-1-2 m-auto'}>
                                        <TextInput type="text" readOnly showLabelIcon label={t('label_user_id')} value={inputValue} onChange={handleChange} required />
                                        {errors.getChalFac && <div className='fieldError'>{errors.getChalFac.message}</div>}
                                    </div>
                                    <div className={isMobile ? 'w-full m-auto' : 'w-1-2 m-auto'}>
                                        <Dropdown
                                            childList={childList}
                                            dropdownHeader="Preferred Language"
                                            feedbackType="info"
                                            helperText="Help: This is a hint"
                                            isRequired
                                            label={t('Preferred Language')}
                                            placeholder={t('Default to region')}
                                            selection="single"
                                            setSelectedValue={(val) => setPreferredLanguage(val[0].displayText)}
                                            size="large"
                                        />
                                    </div>
                                    <div className={isMobile ? 'w-full m-auto' : 'w-1-2 m-auto'}>
                                        <Button className='full-button' size="medium" text='Continue' type='submit' />
                                    </div>
                                </form>
                                <ForgotFooter />
                            </div>
                        </div>
                    )}
                    {!isRegistration && userIdValidated && userType === 'INTL EMPLOYEE' && (
                        <div className="card card-layout">
                            <div className='card-content'>
                                <div className='idm_logo_background'></div>
                                <div>
                                    <h2>Welcome to myidM</h2>

                                </div>
                                <div className='info-container'>
                                    {/* <p> Congratulations on your first time here!</p>
                                <p> You’re almost there! we just need a little bit of information</p> */}
                                    <p>First Time Login - Registration: Please enter your Identity</p>
                                </div>
                                <hr className='custom-hr' />
                                <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
                                    <div className={isMobile ? 'w-full m-auto' : 'w-1-2'}>
                                        <TextInput type="text" readOnly showLabelIcon label={t('label_user_id')} value={inputValue} onChange={handleChange} />
                                        {errors.getChalFac && <div className='fieldError'>{errors.getChalFac.message}</div>}
                                    </div>
                                    <div className={isMobile ? 'w-full m-auto' : 'w-1-2'}>
                                        <TextInput type="text" value={this} {...register('dob', { required: 'Date of Birth is required' })} onChange={(event) => handleDateChange(event, 'dob')} required label={t('Date of Birth (mm/dd/yyyy)')} />
                                        {dateErrors.dob && <p style={{ color: 'red' }}>{dateErrors.dob}</p>}
                                    </div>
                                    <div className={isMobile ? 'w-full m-auto' : 'w-1-2'}>
                                        <TextInput type="text" value={this} {...register('hireDate', { required: 'Hire Date is required' })} onChange={(event) => handleDateChange(event, 'hireDate')} required label={t('Hire Date (mm/dd/yyyy)')} />
                                        {dateErrors.hireDate && <p style={{ color: 'red' }}>{dateErrors.hireDate}</p>}
                                    </div>
                                    <div className={isMobile ? 'w-full m-auto' : 'w-1-2'}>
                                        <Dropdown
                                            childList={childList}
                                            dropdownHeader="Preferred Language"
                                            feedbackType="info"
                                            helperText="Help: This is a hint"
                                            isRequired
                                            label={t('Preferred Language')}
                                            placeholder={t('Default to region')}
                                            selection="single"
                                            setSelectedValue={(val) => setPreferredLanguage(val[0].displayText)}
                                            size="large"
                                        />
                                    </div>
                                    <Button className='full-button' size="medium" text='Continue' type='submit' />
                                </form>
                            </div>
                        </div>
                    )}
                    {isRegistration && stepNumber === 1 && addMandatoryQuestions() && (
                        <div className="card card-layout reg-card">
                            <div className='card-content'>
                                <div className='idm_logo_background'></div>
                                <div className='info-container'>
                                    <h2>Enter Security Questions</h2>
                                </div>
                                <hr className='custom-hr' />

                                <div className='form' style={{ display: 'flex', flexDirection: 'column' }}>

                                    <form onSubmit={handleSubmit(onSecurityQstnsSubmit)} className='w-full'>
                                        <div>
                                            {secQns.map((secQn) => (
                                                <div className={isMobile ? 'form-mobile' : 'flex'}>

                                                    <Dropdown
                                                        {...secQn.question}
                                                        childList={securityQuestions}
                                                        dropdownHeader={secQn.ddHeader}
                                                        feedbackType="info"
                                                        helperText="Help: This is a hint"
                                                        isRequired
                                                        label={t(secQn.lbl)}
                                                        placeholder={t('Select')}
                                                        selection="single"
                                                        setSelectedValue={(val) => addSelectedQuestions(val[0].displayText, secQn)}
                                                        size="medium"
                                                    />

                                                    <TextInput type="text" value={this} {...register(secQn.answer)} className='align-fields' showLabelIcon required label={t(secQn.answerlbl)} />
                                                    {errors.answer1 && <span>{errors.answer1.message}</span>}
                                                </div>
                                            ))}
                                        </div>
                                        <div>
                                            {secQnsTxt.map((secQnTxt) => (
                                                <div className={isMobile ? '' : 'flex'}>
                                                    <TextInput type="text" className={isMobile ? 'form-mobile' : ''} value={this} {...register(secQnTxt.question)} showLabelIcon required label={t(secQnTxt.ddHeader)} />
                                                    <TextInput type="text" className={isMobile ? 'form-mobile' : 'align-fields'} value={this} {...register(secQnTxt.answer)} showLabelIcon required label={t(secQnTxt.answerlbl)} />
                                                    {errors.answer1 && <span>{errors.answer1.message}</span>}
                                                </div>
                                            ))}
                                        </div>
                                        {secQnsTxt.length < 2 && (<div className='link' onClick={addSecQnTxt}>
                                            {/* <Icon icon='Add' size={16} /> */}
                                            Add Extra Security Question (Optional)
                                        </div>)}
                                        {secQnsTxt.length > 0 && (<div className='link' onClick={removeSecQnTxt}>
                                            - Remove optional security question
                                        </div>)}

                                        <div style={{ gap: '8px' }} className={!isMobile ? "d-flex" : "d-grid"}>
                                            <Button className='full-button' size="medium" text='Previous' onClick={() => setProgress(-1)} />
                                            <Button className='full-button' size="medium" text='Next' type="submit" />
                                        </div>
                                    </form>
                                </div>
                                <ForgotFooter />
                            </div>
                        </div>
                    )}
                    {stepNumber === 2 && (
                        <div className="card card-layout reg-card">
                            <div className='card-content'>
                                <div className='idm_logo_background'></div>
                                <div>
                                    <h1>Type in your new password</h1>
                                </div>
                                <hr className='custom-hr' />
                                <form onSubmit={handleSubmit(onFinalSubmit)} >
                                    <div >
                                        <div style={{ display: 'flex' }}>
                                            <TextInput type={type} value={this} {...register('password', { required: 'Password is required' })} showLabelIcon required label={t('New Password')} style={{ width: '-webkit-fill-available' }}
                                                onChange={(e) => powdMessageValidation(e.target.value)} />
                                            <div className="flex justify-around items-center " id='hide-icon' onClick={handleToggle}>
                                                <Icon className="visible" icon={icon} size={16} onClick={handleToggle} />
                                            </div>
                                        </div>
                                        {errors.password && <div className='fieldError'>{errors.password.message}</div>}
                                        <div className='passGuides'>
                                            <h5>Password Guidelines</h5>
                                            <h5>Must:</h5>
                                            <li className='hintText' style={{ color: getHintColor(passwordHints.minLength || passwordHints.hasTwoNumbers) }}>Have at least eight characters ( maximum 14 characters), including two numbers and four letters.</li>
                                            <li className='hintText' style={{ color: getHintColor(passwordHints.hasLowercase) }}>Have at least one lowercase letter( a-z)</li>
                                            <li className='hintText' style={{ color: getHintColor(passwordHints.hasUppercase) }}>Have at least one uppercase letter( A-Z)</li>
                                            <li className='hintText' style={{ color: getHintColor(passwordHints.hasSpecialChar) }}>Contains at least one special character from any of the following values : !^*()_|~`{ }.#[]:";'&lt;	&gt;?,</li>
                                            <h5>Must Not:</h5>
                                            <li className='hintText'>Match any of the four previous Passwords</li>
                                            <li className='hintText' style={{ color: getHintColor(passwordHints.noRepeatingChars) }}>Have more than two repeating characters or numbers in sequence</li>
                                            <li className='hintText'>Use your login name, personal information about yourself (Example: first name, last name, email address)</li>
                                            <li className='hintText' style={{ color: getHintColor(passwordHints.notStartWithNumber) }}>Begin with number as the first character of the password</li>
                                            <li className='hintText' style={{ color: getHintColor(passwordHints.notEndWithNumber) }}>End with number as the last character of the password</li>
                                            <li className='hintText' style={{ color: getHintColor(passwordHints.noSequentialChars) }}>Contain characters or numbers in sequence (Example: abc, xyz, 123)</li>
                                            <li className='hintText'>Contains keywords such as year (Example: 2020, 1998), season name, month name</li>
                                            <li className='hintText' style={{ color: getHintColor(passwordHints.noForbiddenKeywords) }}>Contains keywords such as company or PepsiCo Product name</li>
                                        </div>
                                    </div>
                                    <div >
                                        <TextInput type="password" value={this} {...register('newPassword', {
                                            required: 'Retype Password is required',
                                            validate: (value) => value === password || "Passwords do not match"
                                        })} showLabelIcon required label={t('Retype Password')} />
                                        {errors.newPassword && <div className='fieldError'>{errors.newPassword.message}</div>}
                                    </div>
                                    <div className='endPoints'>
                                        <div className='accountInfo'>Your password will change for the following systems</div>
                                        <div className='accountsAlign'>
                                            {endpointAccounts.map(point => (
                                                <span className='endPointInfo'>{point} </span>
                                            ))}
                                        </div>
                                    </div>

                                    <div>
                                        <Checkbox label="" size="small" onUpdate={(event) => { onConfirmation(event) }} text="By Clicking this box I agree to the Terms and Conditions" />
                                    </div>
                                    <br /><br />
                                    <div style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
                                        <Button size='medium' onClick={() => setProgress(-1)} >Previous</Button>
                                        <Button type="submit" size='medium' className="button" disabled={!isValid || !isConfirmed}>Confirm</Button>
                                    </div>
                                </form>
                                <ForgotFooter />
                            </div>
                        </div>
                    )}
                    {stepNumber === 3 && passwordSuccess && (
                        <div className="card card-layout reg-card">
                            <div className='card-content'>
                                <div>
                                    <h1>Your Password is all set!</h1>
                                </div>
                                <hr className='custom-hr' />
                                <div className='registration_success_background'>
                                </div>
                                <div>Your password has been updated for the following systems</div>
                                <div style={{ justifyContent: 'center', display: 'flex' }}>
                                    <table className='borderLessTable'>
                                        <tr style={{ textAlign: 'left' }}>
                                            <th>User ID</th>
                                            <th>System Name</th>
                                        </tr>
                                        <tr>
                                            <td className='text-left'>{inputValue}</td>
                                            <td className='text-right'>MyIDM</td>
                                        </tr>
                                        <tr>
                                            <td className='text-left'>{inputValue}</td>
                                            <td className='text-right'>IDX</td>
                                        </tr>
                                    </table>
                                </div>
                                <div className='flex w-full'>
                                    <Button className='full-button' size="medium" onClick={handleLoginClick}>Continue to next step </Button>
                                </div>
                                <ForgotFooter />
                            </div>
                        </div>
                    )}
                    {forgotPassword && (
                        <ForgotPassword />
                    )}
                </div>
            </div>
        </div>
    )
}

export default Registration;