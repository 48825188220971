import React, { useEffect, useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { Accordion, AccordionItem, Button, Card, Font, Slot } from 'pepsico-ds';
import './home.css';
import { useTranslation } from 'react-i18next';
import Welcome from './welcome';
// import LanguageDropdown from '../../common/languageDropdown';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

const HomeView = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { oktaAuth } = useOktaAuth();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const checkAuthentication = async () => {
            const authenticated = await oktaAuth.isAuthenticated();
            setIsAuthenticated(authenticated);

            if (!authenticated && !window.location.hostname.includes('localhost')) {
                navigate('/login');
            }
        };
        checkAuthentication();
    }, [oktaAuth, navigate]);

    const changePassword = () => {
        navigate('/change-password');
    }

    const createForm= () =>{
        navigate('/form/form-builder');
    }

    const navigateToQuickLinks = (path) => () => {
        navigate(path);
    }
    if (!isAuthenticated) {
        return <div>Loading...</div>;
    }
    return (
        <div id='landingPage' className='landingAlignments' style={{ display: 'grid' }}>
            <Welcome />
            {/* <PowerBi /> */}
            <div className='card'>
                <div className='card-content'>
                    <Accordion alignment="right" onUpdate={function Qa() { }} size="large" state="enable">
                        <AccordionItem alignment="left" label={t('quick_links')} expanded="true">
                            <div className='flex'>
                                <div className='quickLinks cursor-pointer' onClick={navigateToQuickLinks('/approvals')}>
                                    <div className='approval_logo_background'>

                                    </div>
                                    <div className='quickLinksLabel'><span>{t('View request pending/approved')} </span></div>
                                </div>
                                <div className='quickLinks cursor-pointer' onClick={createForm} >
                                    <div className='password_logo_background'></div>
                                    <div className='quickLinksLabel'><span>Create Form</span></div>
                                </div>
                                <div className='quickLinks cursor-pointer' onClick={changePassword} >
                                    <div className='password_logo_background'></div>
                                    <div className='quickLinksLabel'><span>Change Password</span></div>
                                </div>
                                <div className='quickLinks cursor-pointer'>
                                    <div className='profile_logo_background'></div>
                                    <div className='quickLinksLabel'><span>Profile Update</span></div>
                                </div>
                                <div className='quickLinks cursor-pointer'>
                                    <div className='message_logo_background'></div>
                                    <div className='quickLinksLabel'><bold>Message Board</bold></div>
                                </div>
                            </div>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
            <div className='flex' style={{ gap: '12px' }}>
                <div className="card left-section card-layout">
                    <div className='card-content'>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span className='sectionHeader'>Approvals</span>
                            <Link to='/approvals' className='link'>View all</Link>
                        </div>
                        {/* <hr className='custom-hr' /> */}
                        <Accordion alignment="right" onUpdate={function Qa() { }} size="large" state="enable">
                            {/* <AccordionItem alignment="right" label="Update alternate email adress "
                                subTitle="Details" title='Update alternate email adress' avatar={{
                                    name: 'Jon Doe'
                                }}
                                badge={{
                                    isCopyable: false,
                                    text: 'Badge'
                                }}>
                                <div className='innerTitleName'>
                                    You can update your alternate email address
                                </div>
                                <div className='innerTitleInfo'>
                                    <div className='titleTime'>
                                        Estimated time 3 mins
                                    </div>
                                    <div className='link' style={{ fontSize: '10px' }}> Get Started</div>
                                </div>
                            </AccordionItem> */}
                            <hr className='custom-hr' />
                            {/* <AccordionItem alignment="right" label="Associate PAAS Account"
                                subTitle="Details" title='Associate PAAS Account' avatar={{
                                    name: 'Jon Doe'
                                }}
                                badge={{
                                    isCopyable: false,
                                    text: 'Badge'
                                }}>
                                <div className='innerTitleName'>
                                    You can update your alternate email address
                                </div>
                                <div className='innerTitleInfo'>
                                    <div className='titleTime'>
                                        Estimated time 3 mins
                                    </div>
                                    <div className='link' style={{ fontSize: '10px' }}> Get Started</div>
                                </div>
                            </AccordionItem> */}
                        </Accordion>
                        {/* <div style={{ margin: '12px', justifyContent: 'flex-end', display: 'flex' }}>
                            <Button size="medium" text="Approve" />
                        </div> */}
                        {/* <hr className='custom-hr' /> */}
                    </div>
                </div>
                <div className="card left-section card-layout">
                    <div className='card-content'>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span className='sectionHeader'>Tasks List</span>
                            <Link to='/approvals' className='link'>View all</Link>
                        </div>
                        <hr className='custom-hr' />
                        <Accordion alignment="right" onUpdate={function Qa() { }} size="medium" state="enable">
                            <AccordionItem alignment="right" label="Update alternate email adress "
                                subTitle="Details" title='Update alternate email adress' avatar={{
                                    name: 'Jon Doe'
                                }}
                                badge={{
                                    isCopyable: false,
                                    text: 'Badge'
                                }}>
                                <div className='innerTitleName'>
                                    You can update your alternate email address
                                </div>
                                <div className='innerTitleInfo'>
                                    <div className='titleTime'>
                                        Estimated time 3 mins
                                    </div>
                                    <div className='link' style={{ fontSize: '10px' }}> Get Started</div>
                                </div>
                            </AccordionItem>
                            <hr className='custom-hr' />
                            <AccordionItem alignment="right" label="Associate PAAS Account"
                                subTitle="Details" title='Associate PAAS Account' avatar={{
                                    name: 'Jon Doe'
                                }}
                                badge={{
                                    isCopyable: false,
                                    text: 'Badge'
                                }}>
                                <div className='innerTitleName'>
                                    You can update your alternate email address
                                </div>
                                <div className='innerTitleInfo'>
                                    <div className='titleTime'>
                                        Estimated time 3 mins
                                    </div>
                                    <div className='link' style={{ fontSize: '10px' }}> Get Started</div>
                                </div>
                            </AccordionItem>
                        </Accordion>
                        <hr className='custom-hr' />
                    </div>
                </div>
                {/* <div className='left-section'>
                    <div className='ssoCard'>
                        <Card
                            border

                            ellipsis
                            icon="info"
                            linkProps={{
                                label: 'Link',
                                onClick: function Qa() { },
                                size: 'medium'
                            }}
                            onEllipsisClick={function Qa() { }}
                            onIconClick={function Qa() { }}
                            primaryButtonProps={{

                                onClick: function Qa() { },
                                size: 'small',
                                text: 'Primary',
                                variant: 'primary'
                            }}
                            secondaryButtonProps={{

                                onClick: function Qa() { },
                                size: 'small',
                                text: 'Secondary',
                                variant: 'secondary'
                            }}
                            tertiaryButtonProps={{

                                onClick: function Qa() { },
                                size: 'small',
                                text: 'Tertiary',
                                variant: 'tertiary'
                            }}
                            title="SSO"
                        >
                            <Slot
                                detail="Use this for SAML App Onboarding."
                                style={{
                                    width: '100%', textAlign: 'left'
                                }}
                                title="SSO"
                            />
                        </Card>
                    </div>
                    <div className='ssoCard'>
                        <Card
                            border

                            ellipsis
                            icon="info"
                            linkProps={{
                                label: 'Link',
                                onClick: function Qa() { },
                                size: 'medium'
                            }}
                            onEllipsisClick={function Qa() { }}
                            onIconClick={function Qa() { }}
                            primaryButtonProps={{

                                onClick: function Qa() { },
                                size: 'small',
                                text: 'Primary',
                                variant: 'primary'
                            }}
                            secondaryButtonProps={{

                                onClick: function Qa() { },
                                size: 'small',
                                text: 'Secondary',
                                variant: 'secondary'
                            }}
                            tertiaryButtonProps={{

                                onClick: function Qa() { },
                                size: 'small',
                                text: 'Tertiary',
                                variant: 'tertiary'
                            }}
                            title="PKI"
                        >
                            <Slot
                                detail="Use this placeholder to embed an instance of the library or your local component."
                                style={{
                                    width: '100%', textAlign: 'left'
                                }}
                                title="PKI"
                            />
                        </Card>
                    </div>

                    <div className='ssoCard'>
                        <Card
                            border

                            ellipsis
                            icon="info"
                            linkProps={{
                                label: 'Link',
                                onClick: function Qa() { },
                                size: 'medium'
                            }}
                            onEllipsisClick={function Qa() { }}
                            onIconClick={function Qa() { }}
                            primaryButtonProps={{

                                onClick: function Qa() { },
                                size: 'small',
                                text: 'Primary',
                                variant: 'primary'
                            }}
                            secondaryButtonProps={{

                                onClick: function Qa() { },
                                size: 'small',
                                text: 'Secondary',
                                variant: 'secondary'
                            }}
                            tertiaryButtonProps={{

                                onClick: function Qa() { },
                                size: 'small',
                                text: 'Tertiary',
                                variant: 'tertiary'
                            }}
                            title="AD"
                        >
                            <Slot
                                detail="Request related to New Azure Application"
                                style={{
                                    width: '100%', textAlign: 'left'
                                }}
                                title="New Azure Application"
                            />
                        </Card>
                    </div>
                    <div className='ssoCard'>
                        <Card
                            border

                            ellipsis
                            icon="info"
                            linkProps={{
                                label: 'Link',
                                onClick: function Qa() { },
                                size: 'medium'
                            }}
                            onEllipsisClick={function Qa() { }}
                            onIconClick={function Qa() { }}
                            primaryButtonProps={{

                                onClick: function Qa() { },
                                size: 'small',
                                text: 'Primary',
                                variant: 'primary'
                            }}
                            secondaryButtonProps={{

                                onClick: function Qa() { },
                                size: 'small',
                                text: 'Secondary',
                                variant: 'secondary'
                            }}
                            tertiaryButtonProps={{

                                onClick: function Qa() { },
                                size: 'small',
                                text: 'Tertiary',
                                variant: 'tertiary'
                            }}
                            title="myIDM"
                        >
                            <Slot
                                detail="Use this for Profile management
                                "
                                style={{
                                    width: '100%', textAlign: 'left'
                                }}
                                title="myIAG"
                            />
                        </Card>
                    </div>
                </div>
                <div className='card titleCard'>
                    <div>
                        <h1 style={{ margin: 'auto', marginLeft: '8px' }}>Task List</h1>
                        <hr className='custom-title-hr' />
                        <div>
                            <div className='innerTitle'>
                                Update alternate email adress
                            </div>
                            <div className='innerTitleName'>
                                You can update your alternate email address
                            </div>
                            <div className='innerTitleInfo'>
                                <div className='titleTime'>
                                    Estimated time 3 mins
                                </div>
                                <div className='link' style={{ fontSize: '10px' }}> Get Started</div>
                            </div>
                        </div>

                        <hr className='custom-title-hr' />

                        <div >
                            <div className='innerTitle'>
                                Associate PAAS Account
                            </div>
                            <div className='innerTitleName'>
                                You can update your alternate email address
                            </div>
                            <div className='innerTitleInfo'>
                                <div className='titleTime'>
                                    Estimated time 3 mins
                                </div>
                                <div className='link' style={{ fontSize: '10px' }}> Get Started</div>
                            </div>
                        </div>
                        <hr className='custom-title-hr' />
                    </div>
                </div> */}
            </div>
            {/* <div>
                <AgGridComponent
                    rowData={rowData}
                    columnDefs={columnDefs}
                    pagination={true}
                    pageSize={10}
                />
            </div> */}
            <div className='support'>
                <div style={{ padding: '8px', textAlign: 'center' }}>Need help? Talk to an expert</div>
            </div>

        </div>
    );
}

export default HomeView;
