import assignment_ind from '../../../assets/icons/accounts-icons/assignment_ind.png';
import FormAttributes from '../../workflow/test';
import './employee.css';
import { useNavigate } from 'react-router-dom';

const ManageAccounts = () => {
    const navigate = useNavigate();

    const updateProfile = () => {
        navigate('/update-profile');
    }
    const updatePhone = () => {
        navigate('/update-phone');
    }
    const changePassword = () => {
        navigate('/change-password');
    }
    const nonEmployeeExtension = () => {
        navigate('/extension-request');
    }

    return (
        <div id="mangeAccounts" >
            <div className='card accountCard'>
                <h3>Manage Accounts</h3>
                <div className="accountContent card-content">
                    <div className='d-flex accountLayout'>
                        <div className="card accountReqCard">
                            <div className="card-content">
                                <div  className='quickLinks cursor-pointer' onClick={updateProfile} >
                                    <img src={assignment_ind} alt="Gpid" />
                                    <div className="cardLabel">Update Profile</div>
                                    <div className="detailsValue">Validate your personal info & mobile</div>
                                </div>
                            </div>
                        </div>
                        <div className="card accountReqCard">
                            <div className="card-content">
                                <div>
                                    <img src={assignment_ind} alt="Gpid" />
                                    <div className="cardLabel">Validate alternate email</div>
                                    <div className="detailsValue">I’m the description for this card</div>
                                </div>
                            </div>
                        </div>
                        <div className="card accountReqCard">
                            <div className="card-content">
                                <div className='quickLinks cursor-pointer' onClick={updatePhone}>
                                    <img src={assignment_ind} alt="Gpid" />
                                    <div className="cardLabel">Update phone & non-Pepsico email</div>
                                    <div className="detailsValue">I’m the description for this card</div>
                                </div>
                            </div>
                        </div>
                        <div className="card accountReqCard">
                            <div className="card-content">
                                <div>
                                    <img src={assignment_ind} alt="Gpid" onClick={nonEmployeeExtension} />
                                    <div className="cardLabel">Non-employee extension for self</div>
                                    <div className="detailsValue">I’m the description for this card</div>
                                </div>
                            </div>
                        </div>
                        <div className="card accountReqCard">
                            <div className="card-content">
                                <div   className='quickLinks cursor-pointer' onClick={changePassword}>
                                    <img src={assignment_ind} alt="Gpid" />
                                    <div className="cardLabel">Change password</div>
                                    <div className="detailsValue">I’m the description for this card</div>
                                </div>
                            </div>
                        </div>
                        <div className="card accountReqCard">
                            <div className="card-content">
                                <div>
                                    <img src={assignment_ind} alt="Gpid" />
                                    <div className="cardLabel">Mobile change password</div>
                                    <div className="detailsValue">I’m the description for this card</div>
                                </div>
                            </div>
                        </div>                        
                        <div className="card accountReqCard">
                            <div className="card-content">
                                <div>
                                    <img src={assignment_ind} alt="Gpid" />
                                    <div className="cardLabel">Change security Q & A</div>
                                    <div className="detailsValue">I’m the description for this card</div>
                                </div>
                            </div>
                        </div>
                        <div className="card accountReqCard">
                            <div className="card-content">
                                <div>
                                    <img src={assignment_ind} alt="Gpid" />
                                    <div className="cardLabel">Update security PIN</div>
                                    <div className="detailsValue">I’m the description for this card</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FormAttributes />
            </div>
        </div>
    );
}

export default ManageAccounts;