import React, { useEffect, useState } from 'react';
import { TextInput, Button, Link } from 'pepsico-ds';
import { useTranslation } from 'react-i18next';
import './auth.css';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from "@okta/okta-react"

function Login() {
    console.log('tokens');

    const { oktaAuth } = useOktaAuth()
    const navigate = useNavigate();
    useEffect(() => {
        const checkAuthenticationAndRedirect = async () => {
            console.log('tokens');
            const isAuthenticated = await oktaAuth.isAuthenticated();
            if (isAuthenticated || window.location.hostname.includes('localhost')) {
                navigate('/home');
            } else {
                await oktaAuth.signInWithRedirect();
            }
        };
        checkAuthenticationAndRedirect();
    }, [oktaAuth, navigate]);
}

export default Login;