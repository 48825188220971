import { Button, DatePickerInput } from 'pepsico-ds';
import React, { useState, useRef, useEffect } from 'react';
import './employee.css';
import AgGridComponent from '../../../common/ag_grid';

const EmployeeRequest = () => {

    const [rowData] = useState([
        { gpid: '01159421', maintenanceType: 'Type 1', firstName: 'John Doe',middleName: 'John Doe',lastName: 'John Doe',manager: 'John Doe',startDate: '10/10/2021',expDate: '01/20/2025' },
        { gpid: '01159422', maintenanceType: 'Type 2', firstName: 'Doe',middleName: 'John Doe',lastName: 'John Doe',manager: 'John Doe',startDate: '10/10/2021',expDate: '01/20/2025' },
        { gpid: '01159423', maintenanceType: 'Type 3', firstName: 'John Doe 1',middleName: 'John Doe',lastName: 'John Doe',manager: 'John Doe',startDate: '10/10/2021',expDate: '01/20/2025' },
        { gpid: '01159424', maintenanceType: 'Type 4', firstName: 'Doe John',middleName: 'John Doe',lastName: 'John Doe',manager: 'John Doe',startDate: '10/10/2021',expDate: '01/20/2025' },
        { gpid: '01159425', maintenanceType: 'Type 5', firstName: 'John Doe',middleName: 'John Doe',lastName: 'John Doe',manager: 'John Doe',startDate: '10/10/2021',expDate: '01/20/2025' },
        { gpid: '01159426', maintenanceType: 'Type 6', firstName: 'John Doe',middleName: 'John Doe',lastName: 'John Doe',manager: 'John Doe',startDate: '10/10/2021',expDate: '01/20/2025' }
    ]);
    const [columnDefs] = useState([
        { headerName: "GPID", field: "gpid" },
        { headerName: "Maintenance Type", field: "maintenanceType" },
        { headerName: "First Name", field: "firstName" },
        { headerName: "Middle Name", field: "middleName" },
        { headerName: "Last Name", field: "lastName" },
        { headerName: "Manager", field: "manager" },
        { headerName: "Start Date", field: "startDate" },
        { headerName: "Expiry Date", field: "expDate" }
    ]);
    const rowSelection = {
        mode: 'multiRow',
        headerCheckbox: true,
    };  
    const agGridRef = useRef();
    const [checkedIndexes, setCheckedIndexes] = useState([]);
    const [selectedCount, setSelectedCount] = useState(0);
    const [gpids, setGpids] = useState("");

    const getDataFromGrid = (nodes) =>
    {
     // sendDataToApprovals({selectedCount: nodes.length});
       setSelectedCount(nodes.length);
       const names = nodes.map(node => node.data.gpid);
        setGpids(names.join(', '));
    };

    return (
        <div id="employeeRequest">
            <div className="card employeeCard">
                <div className="card-content">Non-Employee Extension</div>
            </div>
            <div className="vertical-group" style={{ display: 'flex', flexDirection: 'column' }}>
                <span className='bold-text' style={{ marginTop: '10px' }}>{selectedCount} item(s) selected</span>
            </div>  
            {selectedCount != 0 && (
            <div style={{width: '100%', height: '100%', paddingLeft: 16, paddingRight: 16, paddingTop: 12, paddingBottom: 12, background: '#FFF2D2', borderRadius: 4, justifyContent: 'flex-end', alignItems: 'flex-start', gap: 8, display: 'inline-flex'}}>
                <div style={{height: 32, paddingTop: 4, paddingBottom: 4, justifyContent: 'flex-start', alignItems: 'center', gap: 10, display: 'flex'}}>
                    <div style={{width: 24, height: 24, position: 'relative'}}>
                        <div className="warning" style={{width: 22, height: 25, left: 1, top: 2, position: 'absolute'}}></div>
                </div>
                </div>
                <div style={{flex: '1 1 0', height: 68, justifyContent: 'flex-end', alignItems: 'center', gap: 8, display: 'flex'}}>
                    <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', display: 'inline-flex'}}>
                    <div style={{ color: 'black', fontSize: 18, fontFamily: 'Inter', fontWeight: '700', lineHeight: 2, wordWrap: 'break-word'}}>Warning </div>
    
                    <div style={{ color: 'black', fontSize: 14, fontFamily: 'Inter', fontWeight: '400', lineHeight: 2, wordWrap: 'break-word'}}>Please confirm this intended action before submitting or approving for extension or reactivation. The following user(s) have not changed their password in over 90 days, in accordance with PepsiCo policy: {gpids}</div>
                    </div>
                </div>
            </div> 
            )}
            <div className='table-container'>
                <AgGridComponent
                    columnDefs={columnDefs}
                    rowData={rowData}
                    rowSelection={rowSelection}
                    ref={agGridRef}
                    checkedIndexes={checkedIndexes}
                    sendDataToApprovals = {getDataFromGrid}
                />
            </div>
            <div className='d-flex w-100 justify-content-end mt-4'>
                <Button size="small" text="Cancel" variant="primaryInverse" iconLeading='close' />
                <Button size="small" text="Revalidate" variant="primary" iconLeading='check' />
            </div>
          
            <div className='d-flex' style={{ gap: '12px' }}>
                <div className="card request-section">
                    <div className="card-content" style={{ display: 'grid' }}>
                        <div className='secondary-text font-700' style={{ fontSize: '18px' }}>
                            Extension request for Self
                        </div>
                        <div className='link font-700 mt-4'>Associate Info</div>
                        <div className='d-flex w-100 justify-content-between mt-2'>
                            <div className='dynamic-width' style={{ '--dynamic-width': '30%' }}>
                                <div className='detailsValue font-700'>Maintainence Type</div>
                                <div className='detailInfo'>John Doe</div>
                            </div>
                            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                                <div className='detailsValue font-700'>First Name</div>
                                <div className='detailInfo'>10/10/2021</div>
                            </div>
                            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                                <div className='detailsValue font-700'>Middle Name</div>
                                <div className='detailInfo'>John Doe</div>
                            </div>
                            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                                <div className='detailsValue font-700'>Last Name</div>
                                <div className='detailInfo'>10/10/2021</div>
                            </div>
                        </div>

                        <div className='link font-700 mt-4'>Job Info</div>
                        <div className='d-flex w-100 justify-content-start mt-2'>
                            <div className='dynamic-width' style={{ '--dynamic-width': '30%' }} >
                                <div className='detailsValue font-700'>Manager</div>
                                <div className='detailInfo'>Consent</div>
                            </div>
                            <div className='dynamic-width' style={{ '--dynamic-width': '23.33%' }}>
                                <div className='detailsValue font-700'>Start Date</div>
                                <div className='detailInfo'>10/10/2021</div>
                            </div>
                            <div className='dynamic-width' style={{ '--dynamic-width': '46.66%' }}>
                                <DatePickerInput
                                    feedbackType="info"
                                    helperText=""
                                    inputSize="small"
                                    label="Expiration Date"
                                    placeholder="mm/dd/yyyy"
                                    required
                                    selected="06/06/2024"
                                    setSelected={function Qa() { }}
                                />
                            </div>
                        </div>
                        <div className='d-flex w-100 justify-content-start mt-4' style={{ gap: '12px' }}>
                            <Button size="medium" text="Save Changes" variant="primary" iconLeading='check' />
                            <Button size="medium" text="Discard" variant="secondary" iconLeading='close' />
                        </div>
                    </div>
                </div>
                <div className="card request-section">
                    <div className="card-content" style={{ display: 'grid' }}>
                        <div className='secondary-text font-700' style={{ fontSize: '18px' }}>
                            Extension request for others
                        </div>
                        <div className='link font-700 mt-4'>Associate Info</div>
                        <div className='d-flex w-100 justify-content-between mt-2'>

                            <div>
                                <div className='detailsValue font-700'>Maintainence Type</div>
                                <div className='detailInfo'>John Doe</div>
                            </div>
                            <div>
                                <div className='detailsValue font-700'>First Name</div>
                                <div className='detailInfo'>10/10/2021</div>
                            </div>
                            <div>
                                <div className='detailsValue font-700'>Middle Name</div>
                                <div className='detailInfo'>John Doe</div>
                            </div>
                            <div>
                                <div className='detailsValue font-700'>Last Name</div>
                                <div className='detailInfo'>10/10/2021</div>
                            </div>
                        </div>

                        <div className='link font-700 mt-4'>Job Info</div>
                        <div className='d-flex w-100 justify-content-start mt-2'>
                            <div className='dynamic-width' style={{ '--dynamic-width': '30%' }} >
                                <div className='detailsValue font-700'>Manager</div>
                                <div className='detailInfo'>Consent</div>
                            </div>
                            <div className='dynamic-width' style={{ '--dynamic-width': '23.33%' }}>
                                <div className='detailsValue font-700'>Start Date</div>
                                <div className='detailInfo'>10/10/2021</div>
                            </div>
                            <div className='dynamic-width' style={{ '--dynamic-width': '46.66%' }}>
                                <DatePickerInput
                                    feedbackType="info"
                                    helperText=""
                                    inputSize="small"
                                    label="Expiration Date"
                                    placeholder="mm/dd/yyyy"
                                    required
                                    selected="06/06/2024"
                                    setSelected={function Qa() { }}
                                />
                            </div>
                        </div>
                        <div className='d-flex w-100 justify-content-start mt-4' style={{ gap: '12px' }}>
                            <Button size="medium" text="Save Changes" variant="primary" iconLeading='check' />
                            <Button size="medium" text="Discard" variant="secondary" iconLeading='close' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EmployeeRequest;