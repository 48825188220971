import React, { useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  Button,
  ButtonIcon,
  Carousel,
  Font,
  Icon,
  ProgressBar,
  ProgressIndicator,
  Slot,
  TabsNavigation,
  Tab,
} from "pepsico-ds";
import "./home.css";
import { useTranslation } from "react-i18next";
import Welcome from "./welcome";
// import LanguageDropdown from '../../common/languageDropdown';
import { useNavigate } from "react-router-dom";
import Footer from "../../common/footer";
import AgGridComponent from "../../common/ag_grid";
// import PowerBIReport from '../workflow/power_bi_reports';

const HomeView2 = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [selectedApp, setSelectedApp] = React.useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [rowData] = useState([
    {
      requestNumber: "01159421",
      requestType: "ISUM Exception Queue 1",
      requester: "John Doe",
      requestedDate: "10/10/2021",
      dueDate: "10/10/2021",
    },
    {
      requestNumber: "01159422",
      requestType: "ISUM Exception Queue 2",
      requester: "Doe",
      requestedDate: "10/10/2021",
      dueDate: "10/10/2021",
    },
    {
      requestNumber: "01159423",
      requestType: "ISUM Exception Queue 3",
      requester: "John Doe 1",
      requestedDate: "10/10/2021",
      dueDate: "10/10/2021",
    },
    {
      requestNumber: "01159424",
      requestType: "ISUM Exception Queue 4",
      requester: "Doe John",
      requestedDate: "10/10/2021",
      dueDate: "10/10/2021",
    },
    {
      requestNumber: "01159425",
      requestType: "ISUM Exception Queue 5",
      requester: "John Doe",
      requestedDate: "10/10/2021",
      dueDate: "10/10/2021",
    },
    {
      requestNumber: "01159426",
      requestType: "ISUM Exception Queue 6",
      requester: "John Doe",
      requestedDate: "10/10/2021",
      dueDate: "10/10/2021",
    },
  ]);

  const [columnDefs] = useState([
    {
      headerName: "Request #",
      field: "requestNumber",
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Request Type",
      field: "requestType",
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Requester",
      field: "requester",
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Requested Date",
      field: "requestedDate",
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Due Date",
      field: "dueDate",
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Actions",
      field: "actions",
      filter: false,
      floatingFilter: false,
    },
  ]);
  const data = JSON.parse(localStorage.getItem("okta-token-storage"));
  const changePassword = () => {
    navigate("/change-password");
  };
  const createForm = () => {
    navigate("/form/form-builder");
  };
  if (!userName && data?.accessToken?.claims?.displayname) {
    // console.log(userName)
    // console.log('Data:', data?.accessToken);
    // console.log('Data:', data?.accessToken?.claims);
    // console.log('Data:', data?.accessToken?.claims?.testClaim);
    setUserName(data?.accessToken?.claims?.displayname);
  }
  const navigateToQuickLinks = (path) => {
    navigate(path);
  };

  const onAppSelect = (app) => {
    console.log("Selected App:", app);
    setSelectedApp(app);
  };

  const renderSlides = () => {
    const slides = [];
    slides.push(
      <div className="flex" key="quicklinks-1">
        <div
          className="quickLinks cursor-pointer"
          onClick={() => navigateToQuickLinks("/approvals")}
        >
          <div className="approval_logo_background"></div>
          <div className="quickLinksLabel">
            <span>{t("viewRequest")}</span>
          </div>
        </div>
        <div className="quickLinks cursor-pointer" onClick={createForm}>
          <div className="password_logo_background"></div>
          <div className="quickLinksLabel">
            <span>{t("createForm")}</span>
          </div>
        </div>
        <div className="quickLinks cursor-pointer" onClick={changePassword}>
          <div className="password_logo_background"></div>
          <div className="quickLinksLabel">
            <span>{t("changePassword")}</span>
          </div>
        </div>
        <div className="quickLinks cursor-pointer">
          <div className="profile_logo_background"></div>
          <div className="quickLinksLabel">
            <span>{t("profileUpdate")}</span>
          </div>
        </div>
      </div>
    );

    if (selectedApp === "myidM") {
      slides.push(
        <div className="flex" key="quicklinks-2">
          <div className="quickLinks cursor-pointer">
            <div className="message_logo_background"></div>
            <div className="quickLinksLabel">
              <strong>{t("messageBoard")}</strong>
            </div>
          </div>
        </div>
      );
    }

    console.log("Slides:", slides); // Debug log to confirm rendering
    return slides;
  };

  const slides = React.useMemo(() => renderSlides(), [selectedApp]);

  React.useEffect(() => {
    console.log("Selected App changed to:", selectedApp); // Debug
  }, [selectedApp]);

  return (
    <div id="landingPage" className="landingAlignments">
      <div
        className="col-12 pl-0 pr-0"
        style={{ display: "grid", gap: "12px" }}
      >
        {/* <PowerBIReport /> */}
        <h5 className="ml-3">Hello {userName}! </h5>
        <div className="changePwdBanner px-4 d-flex">
          <Icon icon="warning" size="small" />
          <div className="changePwdBannerText px-3 ">
            <div style={{ display: "grid" }}>
              <span className="cpHeader"> {t("passwordUpdateRequired")} </span>
              <span> {t("passwordExpirationNotice")} </span>
            </div>
            <div className="changePwdBannerButton">
              <Button
                size="small"
                text={t("changePassword")}
                onClick={changePassword}
              />
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-9">
            <Welcome onSelect={onAppSelect} />
          </div>
          <div className="col-3 pl-0 pr-0" id="activity">
            <div
              className="card left-section card-layout"
              style={{ height: "199px", display: "block" }}
            >
              <div className="card-content" style={{ textAlign: "left" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span className="sectionHeader">Recently Viewed</span>
                  <Icon
                    icon="open_in_full"
                    style={{ color: "#005CBC" }}
                    size="small"
                    onClick={() => navigateToQuickLinks("/approvals")}
                  />
                </div>
                <div>
                  <div className="link bold-text">Pending Approval</div>
                  <div className="link bold-text">Manage Other ...</div>
                  <div className="link bold-text">Tasks Update</div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-3 pl-0 pr-0" id="activity">
            <div className="card left-section card-layout">
              <div className="card-content">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span className="sectionHeader">{t("userActivity")}</span>
                  <Icon
                    icon="open_in_full"
                    style={{ color: "#005CBC" }}
                    size="small"
                    onClick={() => navigateToQuickLinks("/approvals")}
                  />
                </div>
                <div className="innerTitleName text-left">
                  {t("tasksCompleted")}
                </div>
                <div className="d-flex" style={{ gap: "4px" }}>
                  <span className="innerTitleName">7/10</span>
                  <ProgressBar value={12} max={100} />
                  <span className="innerTitleName">75%</span>
                </div>
                <div className="innerTitleName text-left mt-4">
                  {t("approvals")}
                </div>
                <div className="d-flex" style={{ gap: "4px" }}>
                  <span className="innerTitleName">5/10</span>
                  <ProgressBar size="medium" value={12} max={100} />
                  <span className="innerTitleName">50%</span>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className="d-flex">
          <div className="col-9" style={{ display: "grid", gap: "12px" }}>
            <div className="card">
              <div className="card-content">
                <Carousel
                  itemData={slides}
                  showTitle
                  onStepChange={(step) => setCurrentStep(step)}
                  title={t("quick_links")}
                />
              </div>
            </div>
          </div>
          <div className="col-3 pl-0 pr-0" id="activity">
            <div
              className="card left-section card-layout"
              style={{ height: "199px", display: "block" }}
            >
              <div className="card-content" style={{ textAlign: "left" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span className="sectionHeader">Recently Viewed</span>
                  <Icon
                    icon="open_in_full"
                    style={{ color: "#005CBC" }}
                    size="small"
                    onClick={() => navigateToQuickLinks("/approvals")}
                  />
                </div>
                <div>
                  <div className="link bold-text">Pending Approval</div>
                  <div className="link bold-text">Manage Other ...</div>
                  <div className="link bold-text">Tasks Update</div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* /*Week10 Design changes start */}
        <div className="d-flex">
          <div className="col-9">
            <div className="flex" style={{ gap: "12px" }}>
              <div className="card left-action-section card-layout">
                <div className="card-content">
                  <div style={{ padding: "12px", width: "50%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="sectionHeader">
                        {t("Raise requests")}
                      </span>
                      <Icon
                        icon="open_in_full"
                        style={{ color: "#005CBC" }}
                        size="small"
                        onClick={() => navigateToQuickLinks("#")}
                      />
                    </div>
                    <div style={{ padding: "24px 0px" }}>
                      <TabsNavigation
                        onUpdate={function Qa() { }}
                        orientation="horizontal"
                        selectedIndex={0}
                        className="tab-nav-design"
                        style={{ border: "none" }}
                      >
                        <Tab
                          label="New requests"
                          className="tab-design tab-text"
                        />
                        <Tab
                          label="My requests"
                          className="tab-design tab-text"
                        />
                        <Tab
                          label="Current access"
                          className="tab-design tab-text"
                        />
                      </TabsNavigation>

                    </div>
                    <div>
                      <div className="access-request-design">
                        <div className="access-request-design-sub-design">
                          <div className="access-request-design-text">Access request</div>
                        </div>
                        <div className="access-request-design-link">Start </div>
                      </div>
                      <div className="access-request-design">
                        <div className="access-request-design-sub-design">
                          <div className="access-request-design-text">SAP access request</div>
                        </div>
                        <div className="access-request-design-link">Start </div>
                      </div>
                      <div className="access-request-design">
                        <div className="access-request-design-sub-design">
                          <div className="access-request-design-text">Tech access</div>
                        </div>
                        <div className="access-request-design-link">Start </div>
                      </div>
                    </div>
                    <div>

                    </div>
                    {/* <div className='table-container'>

                                            <AgGridComponent
                                                columnDefs={columnDefs}
                                                rowData={rowData}
                                                gridHeight={350}
                                                pagination={false}
                                            />
                                        </div> */}
                  </div>
                  <div style={{ padding: "12px", width: "50%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="sectionHeader">Pending approvals</span>
                      <Icon
                        icon="open_in_full"
                        style={{ color: "#005CBC" }}
                        size="small"
                        onClick={() => navigateToQuickLinks("#")}
                      />
                    </div>
                    <div style={{ padding: "24px 0px" }}>
                      <TabsNavigation
                        onUpdate={function Qa() { }}
                        orientation="horizontal"
                        selectedIndex={0}
                        className="tab-nav-design"
                        style={{ border: "none" }}
                      >
                        <Tab label="Pending" className="tab-design tab-text" />

                        <Tab label="Expiring" className="tab-design tab-text" />
                        <Tab label="Overdue" className="tab-design tab-text" />
                      </TabsNavigation>
                      <div label="Pending">

                      </div>
                    </div>
                    {/* <hr className='custom-hr' /> */}
                    {/* <Accordion alignment="right" onUpdate={function Qa() { }} size="medium" state="enable">
                                            <AccordionItem alignment="right" label="Update alternate email adress "
                                                subTitle="Details" title='Update alternate email adress' avatar={{
                                                    name: 'Jon Doe'
                                                }}
                                                badge={{
                                                    isCopyable: false,
                                                    text: 'Badge',
                                                    size: 'small'
                                                }}>
                                                <div className='innerTitleName'>
                                                    You can update your alternate email address
                                                </div>
                                                <div className='innerTitleInfo'>
                                                    <div className='titleTime'>
                                                        Estimated time 3 mins
                                                    </div>
                                                    <div className='link' style={{ fontSize: '10px' }}> Get Started</div>
                                                </div>
                                            </AccordionItem>
                                            <hr className='custom-hr' />
                                            <AccordionItem alignment="right" label="Associate PAAS Account"
                                                subTitle="Details" title='Associate PAAS Account' avatar={{
                                                    name: 'Jon Doe'
                                                }}
                                                badge={{
                                                    isCopyable: false,
                                                    text: 'Badge'
                                                }}>
                                                <div className='innerTitleName'>
                                                    You can update your alternate email address
                                                </div>
                                                <div className='innerTitleInfo'>
                                                    <div className='titleTime'>
                                                        Estimated time 3 mins
                                                    </div>
                                                    <div className='link' style={{ fontSize: '10px' }}> Get Started</div>
                                                </div>
                                            </AccordionItem>
                                        </Accordion> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 pl-0 pr-0" id="activity">
            <div className="card card-layout" id="announcements">
              <div className="card-content">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span className="sectionHeader">
                    {" "}
                    {t("iamAnnouncements")}{" "}
                  </span>
                  <Icon
                    icon="open_in_full"
                    style={{ color: "#005CBC" }}
                    size="small"
                    onClick={() => navigateToQuickLinks("/approvals")}
                  />
                </div>
                <Accordion
                  alignment="right"
                  onUpdate={function Qa() { }}
                  size="medium"
                  state="enable"
                >
                  <AccordionItem
                    alignment="right"
                    label="Announcements title"
                    subTitle="Lorem ipsum Lorem ipsum Lorem..."
                    badge={{
                      isCopyable: false,
                      text: "New",
                    }}
                  >
                    <div className="innerTitleName">
                      You can update your alternate email address
                    </div>
                    <div className="innerTitleInfo">
                      <div className="titleTime">Estimated time 3 mins</div>
                      <div className="link" style={{ fontSize: "10px" }}>
                        {" "}
                        Get Started
                      </div>
                    </div>
                  </AccordionItem>

                  <AccordionItem
                    alignment="right"
                    label="Announcements title"
                    subTitle="Lorem ipsum Lorem ipsum Lorem..."
                    badge={{
                      isCopyable: false,
                      text: "New",
                    }}
                  >
                    <div className="innerTitleName">
                      You can update your alternate email address
                    </div>
                    <div className="innerTitleInfo">
                      <div className="titleTime">Estimated time 3 mins</div>
                      <div className="link" style={{ fontSize: "10px" }}>
                        {" "}
                        Get Started
                      </div>
                    </div>
                  </AccordionItem>

                  <AccordionItem
                    alignment="right"
                    label="Announcements title"
                    subTitle="Lorem ipsum Lorem ipsum Lorem..."
                    badge={{
                      isCopyable: false,
                      text: "New",
                    }}
                  >
                    <div className="innerTitleName">
                      You can update your alternate email address
                    </div>
                    <div className="innerTitleInfo">
                      <div className="titleTime">Estimated time 3 mins</div>
                      <div className="link" style={{ fontSize: "10px" }}>
                        {" "}
                        Get Started
                      </div>
                    </div>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        {/* /*Week10 Design changes end */}
        {/* <div className="d-flex">
          <div className="col-9">
            <div className="flex" style={{ gap: "12px" }}>
              <div className="card left-action-section card-layout">
                <div className="card-content">
                  <div style={{ padding: "12px", width: "50%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="sectionHeader">{t("approvals")}</span>
                      <Icon
                        icon="open_in_full"
                        style={{ color: "#005CBC" }}
                        size="small"
                        onClick={() => navigateToQuickLinks("/approvals")}
                      />
                    </div>
                    <div className="table-container">
                      <AgGridComponent
                        columnDefs={columnDefs}
                        rowData={rowData}
                        gridHeight={350}
                        pagination={false}
                      />
                    </div>
                  </div>
                  <div style={{ padding: "12px", width: "50%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="sectionHeader">Tasks List</span>
                      <Icon
                        icon="open_in_full"
                        style={{ color: "#005CBC" }}
                        size="small"
                        onClick={() => navigateToQuickLinks("/approvals")}
                      />
                    </div>
                    <hr className="custom-hr" />
                    <Accordion
                      alignment="right"
                      onUpdate={function Qa() { }}
                      size="medium"
                      state="enable"
                    >
                      <AccordionItem
                        alignment="right"
                        label="Update alternate email adress "
                        subTitle="Details"
                        title="Update alternate email adress"
                        avatar={{
                          name: "Jon Doe",
                        }}
                        badge={{
                          isCopyable: false,
                          text: "Badge",
                          size: "small",
                        }}
                      >
                        <div className="innerTitleName">
                          You can update your alternate email address
                        </div>
                        <div className="innerTitleInfo">
                          <div className="titleTime">Estimated time 3 mins</div>
                          <div className="link" style={{ fontSize: "10px" }}>
                            {" "}
                            Get Started
                          </div>
                        </div>
                      </AccordionItem>
                      <hr className="custom-hr" />
                      <AccordionItem
                        alignment="right"
                        label="Associate PAAS Account"
                        subTitle="Details"
                        title="Associate PAAS Account"
                        avatar={{
                          name: "Jon Doe",
                        }}
                        badge={{
                          isCopyable: false,
                          text: "Badge",
                        }}
                      >
                        <div className="innerTitleName">
                          You can update your alternate email address
                        </div>
                        <div className="innerTitleInfo">
                          <div className="titleTime">Estimated time 3 mins</div>
                          <div className="link" style={{ fontSize: "10px" }}>
                            {" "}
                            Get Started
                          </div>
                        </div>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 pl-0 pr-0" id="activity">
            <div className="card card-layout" id="announcements">
              <div className="card-content">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span className="sectionHeader">
                    {" "}
                    {t("iamAnnouncements")}{" "}
                  </span>
                  <Icon
                    icon="open_in_full"
                    style={{ color: "#005CBC" }}
                    size="small"
                    onClick={() => navigateToQuickLinks("/approvals")}
                  />
                </div>
                <Accordion
                  alignment="right"
                  onUpdate={function Qa() { }}
                  size="medium"
                  state="enable"
                >
                  <AccordionItem
                    alignment="right"
                    label="Announcements title"
                    subTitle="Lorem ipsum Lorem ipsum Lorem..."
                    badge={{
                      isCopyable: false,
                      text: "New",
                    }}
                  >
                    <div className="innerTitleName">
                      You can update your alternate email address
                    </div>
                    <div className="innerTitleInfo">
                      <div className="titleTime">Estimated time 3 mins</div>
                      <div className="link" style={{ fontSize: "10px" }}>
                        {" "}
                        Get Started
                      </div>
                    </div>
                  </AccordionItem>

                  <AccordionItem
                    alignment="right"
                    label="Announcements title"
                    subTitle="Lorem ipsum Lorem ipsum Lorem..."
                    badge={{
                      isCopyable: false,
                      text: "New",
                    }}
                  >
                    <div className="innerTitleName">
                      You can update your alternate email address
                    </div>
                    <div className="innerTitleInfo">
                      <div className="titleTime">Estimated time 3 mins</div>
                      <div className="link" style={{ fontSize: "10px" }}>
                        {" "}
                        Get Started
                      </div>
                    </div>
                  </AccordionItem>

                  <AccordionItem
                    alignment="right"
                    label="Announcements title"
                    subTitle="Lorem ipsum Lorem ipsum Lorem..."
                    badge={{
                      isCopyable: false,
                      text: "New",
                    }}
                  >
                    <div className="innerTitleName">
                      You can update your alternate email address
                    </div>
                    <div className="innerTitleInfo">
                      <div className="titleTime">Estimated time 3 mins</div>
                      <div className="link" style={{ fontSize: "10px" }}>
                        {" "}
                        Get Started
                      </div>
                    </div>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className='support'>
                    <div style={{ padding: '8px', textAlign: 'center' }}>{t('needHelp')}</div>
                </div> */}
      </div>
    </div>
  );
};

export default HomeView2;
