import { Button, Filter, Icon, Search, Tag, Dropdown } from 'pepsico-ds';
import React, { useState, useRef, useEffect } from 'react';
import './workflow.css';
import PendingApprovals from './pending_approval';
import { Route, Routes, useNavigate } from 'react-router-dom';

const Approvals = () => {

    const agGridRef = useRef();
    const navigate = useNavigate();
    const [selectedCount, setSelectedCount] = useState(0);
    const [checkedIndexes, setCheckedIndexes] = useState([]);
    const [gridToCard, setGridToCard] = useState(false);
    const [rowData] = useState([
        { requestNumber: '01159421', requestType: 'ISUM Exception Queue 1', requester: 'John Doe', requestedDate: '10/10/2021', dueDate: '01/20/2025' },
        { requestNumber: '01159422', requestType: 'ISUM Exception Queue 2', requester: 'Doe', requestedDate: '10/10/2021', dueDate: '12/5/2024' },
        { requestNumber: '01159423', requestType: 'ISUM Exception Queue 3', requester: 'John Doe 1', requestedDate: '10/10/2021', dueDate: '12/25/2024' },
        { requestNumber: '01159424', requestType: 'ISUM Exception Queue 4', requester: 'Doe John', requestedDate: '10/10/2021', dueDate: '12/20/2024' },
        { requestNumber: '01159425', requestType: 'ISUM Exception Queue 5', requester: 'John Doe', requestedDate: '10/10/2021', dueDate: '12/30/2024' },
        { requestNumber: '01159426', requestType: 'ISUM Exception Queue 6', requester: 'John Doe', requestedDate: '10/10/2021', dueDate: '12/19/2024' },
    ]);
    const rowSelection = {
        mode: 'multiRow',
        headerCheckbox: true,
    };
    const [columnDefs] = useState([
        { headerName: "Request #", field: "requestNumber" },
        { headerName: "Request Type", field: "requestType" },
        { headerName: "Requester", field: "requester" },
        { headerName: "Requested Date", field: "requestedDate" },
        { headerName: "Due Date", field: "dueDate" },
        { headerName: "Actions", field: "actions" }
    ]);
    const [isGridType, setIsGridType] = React.useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchText, setSearchText] = useState('');
    const [displayMode, setDisplayMode] = useState('');
    const [filterString, setFilterString] = useState('');

  const handleSearch = () => {
    setSearchTerm(searchText);
  };

  const handleDataFromPendingApproval = (data) => {
    if(data.selectedCount)
    {
        setSelectedCount(data.selectedCount);
    }
   };
  const setDisplayType = (type) => {
        setIsGridType(type === 'GRID' ? true : false);
        setDisplayMode(type);
          if(type === 'GRID' && agGridRef.current)
          {   
            setCheckedIndexes(agGridRef.current.getSelectedRowData());
            setGridToCard(true);
          }
          else
          {
            setGridToCard(false);
          }
   }

    const onSelectAll = () => {
        if (agGridRef.current) {
            agGridRef.current.selectAllCheckBoxes();
            setSelectedCount(agGridRef.current.countSelected());
        }
        else
        {
            const checkboxes = document.querySelectorAll('.cardCheckBox');
            checkboxes.forEach((checkbox,index) => {
              checkbox.checked = true;
                 setCheckedIndexes((prevIndexes) => {
                   return [...prevIndexes, index];
                });            
            });
            setSelectedCount(checkboxes.length);

        }
      };

      const onClearAll = () => {
        setSelectedCount(0);
        setSearchText('');
        setSearchTerm('');
        const inputElement = document.getElementById('input-search');
        if (inputElement) {
          inputElement.value = '';
        }
        if (agGridRef.current) {
            agGridRef.current.clearAllCheckBoxes();
            agGridRef.current.clearAllFilters();
         }
        else
        {
            const checkboxes = document.querySelectorAll('.cardCheckBox');
            checkboxes.forEach((checkbox) => {
              checkbox.checked = false;
            });
            setCheckedIndexes([]);
        }
      };
    
      const onCheckboxChange = (event,index) => {
        setCheckedIndexes((prevIndexes) => {
            if (event.target.checked) {
                return [...prevIndexes, index];
            } else {
                return prevIndexes.filter((i) => i !== index);
             }
        });
    
        const checkboxes = document.querySelectorAll('.cardCheckBox');
        const checkedCount = Array.from(checkboxes).filter(checkbox => checkbox.checked).length;
        setSelectedCount(checkedCount);
    };
    
    const filterRecords = (dateStr) => {
        console.log(dateStr) ;
        setFilterString(dateStr);
    }

    return (
        <div id="pendingApprovals">
            <span className="page-header">Pending Approvals</span>

            <div className='filters'>
               <div className='d-flex'>
                    <Icon icon="grid_on" className={`${isGridType === true ? 'link' : ''}`} onClick={() => setDisplayType('GRID')} />
                    <Icon icon="list_alt" className={`${isGridType === false ? 'link' : ''}`} onClick={() => setDisplayType('LIST')} />
                </div>
                <div className='d-flex'>
                    <Search
                        onSearch={handleSearch}
                        onSelectOption={function Qa() { }}
                        onUpdate={(value) => setSearchText(value)}
                        placeholderText="Type a search keyword"
                        size="medium" />
                    <Filter
                        label="Default"
                        listLabel="Header"
                        onChange={(value) => filterRecords(value.id)}
                        options={[
                            {
                                id: 'Today',
                                label: 'Today'
                            },
                            {
                                id: 'Tomorrow',
                                label: 'Tomorrow'
                            }
                        ]}
                        selection="single"
                        size="small"
                        value={{
                            id: '1',
                            label: 'Today'
                        }}
                    />

                    <Filter
                        label="Default"
                        listLabel="Header"
                        onChange={(value) => filterRecords(value.id)}
                        options={[
                            {
                                id: 'Tomorrow',
                                label: 'Tomorrow'
                            },
                            {
                                id: 'NextWeek',
                                label: 'NextWeek'
                            }
                        ]}
                        selection="single"
                        size="small"
                        value={{
                            id: 'ThisWeek',
                            label: 'ThisWeek'
                        }}
                    />
                    <Filter
                        label="Default"
                        listLabel="Header"
                        onChange={function Qa() { }}
                        options={[
                            {
                                id: 'ThisMonth',
                                label: 'ThisMonth'
                            },
                            {
                                id: 'NextMonth',
                                label: 'NextMonth'
                            }
                        ]}
                        selection="single"
                        size="small"
                        value={{
                            id: '1',
                            label: 'This Month'
                        }}
                    />
                    <Filter
                        label="Default"
                        listLabel="Header"
                        onChange={function Qa() { }}
                        options={[
                            {
                                id: '1',
                                label: 'All Time'
                            },
                            {
                                id: '2',
                                label: 'Tomorrow'
                            }
                        ]}
                        selection="single"
                        size="small"
                        value={{
                            id: '1',
                            label: 'All Time'
                        }}
                    />
                    <span onClick={onClearAll} className='link bold-text'>Clear All</span>
                </div>
            </div>
             <div  className="parent">
                <div className="vertical-group" style={{ display: 'flex', flexDirection: 'column' }}>
                    <h5 className="child" style={{ fontWeight: 'bold' }}>Bulk Actions</h5>
                    <span className='bold-text' style={{ marginTop: '10px' }}>{selectedCount} item(s) selected</span>
                </div>                
                <button className="child" onClick={onSelectAll} style={{ color: '#005cbc', background: 'none', border: 'none', cursor: 'pointer', fontWeight: 'bold'}}>
                    Select all Items
                </button> 
                <div className='d-flex ' style={{ gap: '30px' }}>
                    <Button onClick={() => alert('Reserved')} size="small" text="Reserve" variant="primaryInverse" iconLeading='close' />
                    <Button onClick={() => alert('Denied')}size="small" text="Deny" variant="primaryInverse" iconLeading='close' />
                    <Button onClick={() => alert('Approved')} size="small" text="Approve" variant="primaryInverse" iconLeading='check' />
                </div>
  
             </div>
             <PendingApprovals rowData={rowData} searchTerm={searchTerm} filterString={filterString} gridToCard={gridToCard} displayType={displayMode} onDataFromPendingApprovals={handleDataFromPendingApproval}></PendingApprovals>
        </div>
    )
};
export default Approvals;