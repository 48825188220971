import { Accordion, AccordionItem, Button, Switcher, CardNavigation, Carousel, Logo, Slot, Tab, TabsNavigation } from 'pepsico-ds';
import './welcome.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

const itemsPerSlide = 3;

const Welcome = ({ onSelect }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedItemIndex, setSelectedItemIndex] = useState(0);
    const [currentConfig, setCurrentConfig] = useState({ totalSlides: 4, itemsPerSlide: 3 });
    let accessTypes = [{ id: 'approve_idm', title: 'View Requests', subTitle: 'abs', path: '/approvals' }, { id: 'change_password_idm', title: 'Change Password', subTitle: 'abs', path: '/change-password' }, { id: 'profile_update_idm', title: 'Update Profile', subTitle: 'abs', path: '/update-profile' }];
    if (window.location.pathname.includes('homev2')) {
        accessTypes = [{ id: 'approve_idm', title: 'View Requests', subTitle: 'abs' }, { id: 'change_password_idm', title: 'Change Password', subTitle: 'abs' }, { id: 'profile_update_idm', title: 'Update Profile', subTitle: 'abs' }];
    }
    // const totalSteps = Math.ceil(accessTypes.length / itemsPerSlide);
    const totalSlides = Math.ceil(accessTypes.length / itemsPerSlide);
    // const getCurrentItems = () => {
    //     const startIndex = currentStep * itemsPerSlide;
    //     const endIndex = startIndex + itemsPerSlide;
    //     return accessTypes.slice(startIndex, endIndex);
    // };

    const [activeType, setActiveType] = useState('');

    const navigateToQuickLinks = (path) => {
        navigate(path);
    };

    const getQuickLinksByType = (accessType) => {
        setActiveType(accessType);
        // onSelect(accessType);
    }

    const selectedTab = (index) => {
        setSelectedTabIndex(index);
    }

    const handleCardChange = (selectedCard) => {
        if (selectedCard === 'myidM') {
            setCurrentConfig({ totalSlides: 4, itemsPerSlide: 3 });
        } else if (selectedCard === 'pepAGM') {
            setCurrentConfig({ totalSlides: 2, itemsPerSlide: 3 });
        }
    };

    const renderSlides = (slidesConfig, headerLabel) => {
        const slides = [];
        let totalSlides = slidesConfig.totalSlides;
        let itemsPerSlide = slidesConfig.itemsPerSlide;

        if (headerLabel === 'myidM') {
            totalSlides = 1;
            itemsPerSlide = 3;
        } else if (headerLabel === 'pepAGM') {
            totalSlides = 1;
            itemsPerSlide = 2;
        }
        for (let i = 0; i < totalSlides; i++) {
            const startIndex = i * itemsPerSlide;
            const endIndex = startIndex + itemsPerSlide;
            const items = accessTypes.slice(startIndex, endIndex);

            slides.push(
                <div style={{ display: 'flex' }} key={`slide-${i}`}>
                    <div className="cards-container">
                        {items.map((accessType, index) => (
                            <div key={`${accessType?.id}-${i}-${index}`} className={`card idmCard`}>
                                <div className="card-content" style={{ margin: 'auto' }} onClick={() => navigateToQuickLinks(accessType?.path)}>
                                    <div className='d-flex'>
                                        <div className={`${accessType?.id}_logo`}></div>
                                        <div style={{ margin: 'auto', display: 'grid' }}>
                                            <a className="bold-text">{`${accessType?.title}`}</a>
                                            <span style={{ textAlign: 'left' }} className='secondary-text'>{`${accessType?.subTitle}`}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
        return slides;
    };

    return (
        <div>
            <div id='welcome-container'>
                <CardNavigation
                    data={[
                        {
                            details: <div className="card card-layout">
                                <div className='card-content'>
                                    <Switcher
                                        items={[
                                            'myidM',
                                            'pepAGM',
                                            'pepAGM',
                                            'pepAGM',
                                            'SSO'
                                        ]}
                                        onUpdate={function Qa() { }}
                                        selectedItemIndex={selectedItemIndex}
                                    />
                                    <Carousel
                                        itemData={renderSlides({ totalSlides: 4, itemsPerSlide: 3 }, 'myidM')}
                                        onStepChange={(step) => setCurrentStep(step)}
                                        showTitle
                                        title={t('iamApplications')}
                                    />
                                </div>
                            </div>,
                            header: {
                                label: 'myidM',
                                onClick: () => handleCardChange('myidM')
                            }
                        },
                        {
                            details: <div className="card card-layout">
                                <div className='card-content'>
                                    <Switcher
                                        items={[
                                            'myidM',
                                            'pepAGM',
                                            'SSO'
                                        ]}
                                        onUpdate={function Qa() { }}
                                        selectedItemIndex={selectedItemIndex}
                                    />
                                    <Carousel
                                        itemData={renderSlides({ totalSlides: 4, itemsPerSlide: 3 }, 'pepAGM')}
                                        onStepChange={(step) => setCurrentStep(step)}
                                        showTitle
                                        title={t('iamApplications')}
                                    />
                                </div>
                            </div>,
                            header: {
                                label: 'pepAGM',
                                onClick: () => handleCardChange('pepAGM')
                            },
                        },
                        {
                            details: <div className="card card-layout">
                                <div className='card-content'>
                                    <Switcher
                                        items={[
                                            'myidM',
                                            'pepAGM',
                                            'SSO'
                                        ]}
                                        onUpdate={function Qa() { }}
                                        selectedItemIndex={selectedItemIndex}
                                    />
                                    <Carousel
                                        itemData={renderSlides({ totalSlides: 4, itemsPerSlide: 3 })}
                                        onStepChange={(step) => setCurrentStep(step)}
                                        showTitle
                                        title={t('iamApplications')}
                                    />
                                </div>
                            </div>,
                            header: {
                                label: 'SSO',
                            },
                        },
                    ]}
                    orientation="horizontal"
                    type="border"
                />

            </div>
        </div>
    );
}

export default Welcome;