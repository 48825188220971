import React, { useEffect, useRef, useState } from "react";
import "./auth.css";
import {
  formEngineRsuiteCssLoader,
  ltrCssLoader,
  rsErrorMessage,
  RsLocalizationWrapper,
  rsTooltip,
  rSuiteComponents,
  rtlCssLoader,
} from "@react-form-builder/components-rsuite";
import { BiDi, BuilderView } from "@react-form-builder/core";
import { FormBuilder, IFormBuilder } from "@react-form-builder/designer";
import * as IntialForm from "../json_forms/IntialForm.json";
// import LanguageDropdown from '../common/languageDropdown';
import { IndexedDbFormStorage } from "../auth/IndexedDbFormStorage";
import { TextField } from '@mui/material';
import { define, string } from '@react-form-builder/core'
import { useSelector, useDispatch } from 'react-redux';


 // Define the custom component
 const matInput = define(TextField, 'MatInput')
 .name('Input') // Name displayed in the component panel
 .props({
   // Existing properties
   label: string.named('Label').default(''),
   // New custom property
   customProperty: string.named('Custom Property').default(''),
 });
const builderComponents = rSuiteComponents.map((c) => c.build());
const builderView = new BuilderView(builderComponents)
  .withErrorMeta(rsErrorMessage.build())
  .withTooltipMeta(rsTooltip.build())
  .withViewerWrapper(RsLocalizationWrapper)
  .withCssLoader(BiDi.LTR, ltrCssLoader)
  .withCssLoader(BiDi.RTL, rtlCssLoader)
  .withCssLoader("common", formEngineRsuiteCssLoader);

const getForm = (_ = "") => JSON.stringify(IntialForm);

const FormBuilderCreate = () => {
  const sideBar = useSelector((state) => state.sideBar);
  console.log('1111111111111111',sideBar)
  const dispatch = useDispatch();
 
  dispatch({ type: false, payload: false });
  const formStorage = new IndexedDbFormStorage("myDatabase", "myStore");
  const builderRef = useRef(null);
  const setRef = (builder) => {
    if (builder) {
      builderRef.current = builder;
      const formJSON = builder.formAsString;
      console.log(formJSON);
    }
  };
  const initialFormData = IntialForm;
 
  
  return (
      
    
    <div style={{marginLeft:'64px'}}>
    <FormBuilder
      builderRef={setRef}
      initialData={initialFormData}
      licenseKey={"PepsiCo-eyJsaWNlbnNlRW5kRGF0ZSI6IjAxLjE4LjIwMjUiLCJyZWxlYXNlRXhwaXJ5IjoiMDEuMTguMjAyNSIsImNvbXBhbnlOYW1lIjoiUGVwc2lDbyIsInJlc3RyaWN0aW9ucyI6eyJTdHJpY3RDaGVjayI6dHJ1ZX19:VpGspVoUev5AOH11Z/a5VtDoAreoAv7H7oq4TffDbxCRZA2DzXOd5tisua+arArbZeMLusDeZZyKwWXR5cf/YV4cFNLr84V/yuSHJOIl+wNSussCQnQnU71DDKbgRp2C"}
      view={builderView}
      getForm={getForm}
      formStorage={formStorage} />
  </div>
  );
}

export default FormBuilderCreate;
