import React, { useState } from 'react';
import { Button, Dropdown, Icon, Tab, TabsNavigation,TextInput, Tag, TextArea, ProgressIndicator } from 'pepsico-ds'; 
import './update_profile.css'
import axios from 'axios';

const UpdatePhone = () => {

    const { REACT_APP_API_ENDPOINT } = process.env;

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [editMode, setEditMode] = useState(false);
    const [stepNumber, setStepNumber] = useState(0);
    const [smsCountryCode, setSmsCountryCode] = useState('');
    const [voiceCountryCode, setVoiceCountryCode] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [nonPepEmail, setNonPepEmail] = useState('');
    const [otpType, setOtpType] = useState("");
    const [smsOTP, setSmsOTP] = useState(false);
    const [callOTP, setCallOTP] = useState(false);
    const [emailOTP, setEmailOTP] = useState(false);
    const [verificationCode, setVerificationCode] = useState(0);
    const [verifyButtonDisabled,setVerifyButtonDisabled] = useState(true);

    const [userName, setUserName] = useState('');
    const [gpid, setgpid] = useState('');
    const userToken = JSON.parse(localStorage.getItem('okta-token-storage'))?.idToken?.idToken;
    const accessToken = JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken?.accessToken;
    let userGpId = '';
    const [gpidErrorMessage, setGpidErrorMessage] = useState('');
    const [isUserValid, setUserValid] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [isUpdatePassword, setUpdatePassword] = useState(true);
    const [isBg, setIsBg] = useState(false);
    const [passwordValue, setpaswordValue] = useState('');
    const [updateStatus, setUpdateStatus] = useState(false);
    
    const data = JSON.parse(localStorage.getItem('okta-token-storage'));
    if (!gpid && data?.accessToken?.claims?.gpid) {
      console.log(gpid)
      userGpId = data?.accessToken?.claims?.gpid;
    }
    if (!userName && data?.accessToken?.claims?.displayname) {
      setUserName(data?.accessToken?.claims?.displayname);
    }
    const selectedTab = (index) => {
        console.log(index);
        setSelectedTabIndex(index)
    }

    const selectEditMode = () => {
        console.log("Edit Mode clicked");
        setEditMode(true);
    }

    const doNothing = () => {
        console.log("Updated");
    }  
    
    const childList = [{ displayText: 'AFGHANISTAN(+93)', id: '93'},
    { displayText: 'ALBANIA(+355)', id: '355'},
    { displayText: 'ALGERIA(+213)', id: '213'},
    { displayText: 'AMERICAN SAMOA(+1-684)', id: '1-684'},
    { displayText: 'ANDORRA(+376)', id: '376'},
    { displayText: 'ANGOLA(+244)', id: '244'},
    { displayText: 'ANGUILLA(+1-264)', id: '1-264'},
    { displayText: 'ANTARCTICA(+672)', id: '672'},
    { displayText: 'ANTIGUA AND BARBUDA(+1-268)', id: '1-268'},
    { displayText: 'ARGENTINA(+54)', id: '54'},
    { displayText: 'ARMENIA(+374)', id: '374'},
    { displayText: 'ARUBA(+297)', id: '297'},
    { displayText: 'AUSTRALIA/CHRISTMAS ISLAND/COCOS ISLANDS(+61)', id: '61'},
    { displayText: 'AUSTRIA(+43)', id: '43'},
    { displayText: 'AZERBAIJAN(+994)', id: '994'},
    { displayText: 'BAHAMAS(+1-242)', id: '1-242'},
    { displayText: 'BAHRAIN(+973)', id: '973'},
    { displayText: 'BANGLADESH(+880)', id: '880'},
    { displayText: 'BARBADOS(+1-246)', id: '1-246'},
    { displayText: 'BELARUS(+375)', id: '375'},
    { displayText: 'BELGIUM(+32)', id: '32'},
    { displayText: 'BELIZE(+501)', id: '501'},
    { displayText: 'BENIN(+229)', id: '229'},
    { displayText: 'BERMUDA(+1-441)', id: '1-441'},
    { displayText: 'BHUTAN(+975)', id: '975'},
    { displayText: 'BOLIVIA(+591)', id: '591'},
    { displayText: 'BOSNIA AND HERZEGOVINA(+387)', id: '387'},
    { displayText: 'BOTSWANA(+267)', id: '267'},
    { displayText: 'BRAZIL(+55)', id: '55'},
    { displayText: 'BRITISH INDIAN OCEAN TERRITORY(+246)', id: '246'},
    { displayText: 'BRITISH VIRGIN ISLANDS(+1-284)', id: '1-284'},
    { displayText: 'BRUNEI(+673)', id: '673'},
    { displayText: 'BULGARIA(+359)', id: '359'},
    { displayText: 'BURKINA FASO(+226)', id: '226'},
    { displayText: 'BURUNDI(+257)', id: '257'},
    { displayText: 'CAMBODIA(+855)', id: '855'},
    { displayText: 'CAMEROON(+237)', id: '237'},
    { displayText: 'CAPE VERDE(+238)', id: '238'},
    { displayText: 'CAYMAN ISLANDS(+1-345)', id: '1-345'},
    { displayText: 'CENTRAL AFRICAN REPUBLIC(+236)', id: '236'},
    { displayText: 'CHAD(+235)', id: '235'},
    { displayText: 'CHILE(+56)', id: '56'},
    { displayText: 'CHINA(+86)', id: '86'},
    { displayText: 'COLOMBIA(+57)', id: '57'},
    { displayText: 'COMOROS(+269)', id: '269'},
    { displayText: 'COOK ISLANDS(+682)', id: '682'},
    { displayText: 'COSTA RICA(+506)', id: '506'},
    { displayText: 'CROATIA(+385)', id: '385'},
    { displayText: 'CUBA(+53)', id: '53'},
    { displayText: 'CURACAO/NETHERLANDS ANTILLES(+599)', id: '599'},
    { displayText: 'CYPRUS(+357)', id: '357'},
    { displayText: 'CZECH REPUBLIC(+420)', id: '420'},
    { displayText: 'DEMOCRATIC REPUBLIC OF THE CONGO(+243)', id: '243'},
    { displayText: 'DENMARK(+45)', id: '45'},
    { displayText: 'DJIBOUTI(+253)', id: '253'},
    { displayText: 'DOMINICA(+1-767)', id: '1-767'},
    { displayText: 'DOMINICAN REPUBLIC(+1-809, 1-829, 1-849)', id: '1-809, 1-829, 1-849'},
    { displayText: 'EAST TIMOR(+670)', id: '670'},
    { displayText: 'ECUADOR(+593)', id: '593'},
    { displayText: 'EGYPT(+20)', id: '20'},
    { displayText: 'EL SALVADOR(+503)', id: '503'},
    { displayText: 'EQUATORIAL GUINEA(+240)', id: '240'},
    { displayText: 'ERITREA(+291)', id: '291'},
    { displayText: 'ESTONIA(+372)', id: '372'},
    { displayText: 'ETHIOPIA(+251)', id: '251'},
    { displayText: 'FALKLAND ISLANDS(+500)', id: '500'},
    { displayText: 'FAROE ISLANDS(+298)', id: '298'},
    { displayText: 'FIJI(+679)', id: '679'},
    { displayText: 'FINLAND(+358)', id: '358'},
    { displayText: 'FRANCE(+33)', id: '33'},
    { displayText: 'FRENCH POLYNESIA(+689)', id: '689'},
    { displayText: 'GABON(+241)', id: '241'},
    { displayText: 'GAMBIA(+220)', id: '220'},
    { displayText: 'GEORGIA(+995)', id: '995'},
    { displayText: 'GERMANY(+49)', id: '49'},
    { displayText: 'GHANA(+233)', id: '233'},
    { displayText: 'GIBRALTAR(+350)', id: '350'},
    { displayText: 'GREECE(+30)', id: '30'},
    { displayText: 'GREENLAND(+299)', id: '299'},
    { displayText: 'GRENADA(+1-473)', id: '1-473'},
    { displayText: 'GUAM(+1-671)', id: '1-671'},
    { displayText: 'GUATEMALA(+502)', id: '502'},
    { displayText: 'GUERNSEY(+44-1481)', id: '44-1481'},
    { displayText: 'GUINEA(+224)', id: '224'},
    { displayText: 'GUINEA-BISSAU(+245)', id: '245'},
    { displayText: 'GUYANA(+592)', id: '592'},
    { displayText: 'HAITI(+509)', id: '509'},
    { displayText: 'HONDURAS(+504)', id: '504'},
    { displayText: 'HONG KONG(+852)', id: '852'},
    { displayText: 'HUNGARY(+36)', id: '36'},
    { displayText: 'ICELAND(+354)', id: '354'},
    { displayText: 'INDIA(+91)', id: '91'},
    { displayText: 'INDONESIA(+62)', id: '62'},
    { displayText: 'IRAN(+98)', id: '98'},
    { displayText: 'IRAQ(+964)', id: '964'},
    { displayText: 'IRELAND(+353)', id: '353'},
    { displayText: 'ISLE OF MAN(+44-1624)', id: '44-1624'},
    { displayText: 'ISRAEL(+972)', id: '972'},
    { displayText: 'ITALY(+39)', id: '39'},
    { displayText: 'IVORY COAST(+225)', id: '225'},
    { displayText: 'JAMAICA(+1-876)', id: '1-876'},
    { displayText: 'JAPAN(+81)', id: '81'},
    { displayText: 'JERSEY(+44-1534)', id: '44-1534'},
    { displayText: 'JORDAN(+962)', id: '962'},
    { displayText: 'KENYA(+254)', id: '254'},
    { displayText: 'KIRIBATI(+686)', id: '686'},
    { displayText: 'KOSOVO(+383)', id: '383'},
    { displayText: 'KUWAIT(+965)', id: '965'},
    { displayText: 'KYRGYZSTAN(+996)', id: '996'},
    { displayText: 'LAOS(+856)', id: '856'},
    { displayText: 'LATVIA(+371)', id: '371'},
    { displayText: 'LEBANON(+961)', id: '961'},
    { displayText: 'LESOTHO(+266)', id: '266'},
    { displayText: 'LIBERIA(+231)', id: '231'},
    { displayText: 'LIBYA(+218)', id: '218'},
    { displayText: 'LIECHTENSTEIN(+423)', id: '423'},
    { displayText: 'LITHUANIA(+370)', id: '370'},
    { displayText: 'LUXEMBOURG(+352)', id: '352'},
    { displayText: 'MACAU(+853)', id: '853'},
    { displayText: 'MACEDONIA(+389)', id: '389'},
    { displayText: 'MADAGASCAR(+261)', id: '261'},
    { displayText: 'MALAWI(+265)', id: '265'},
    { displayText: 'MALAYSIA(+60)', id: '60'},
    { displayText: 'MALDIVES(+960)', id: '960'},
    { displayText: 'MALI(+223)', id: '223'},
    { displayText: 'MALTA(+356)', id: '356'},
    { displayText: 'MARSHALL ISLANDS(+692)', id: '692'},
    { displayText: 'MAURITANIA(+222)', id: '222'},
    { displayText: 'MAURITIUS(+230)', id: '230'},
    { displayText: 'MAYOTTE/REUNION(+262)', id: '262'},
    { displayText: 'MEXICO(+52)', id: '52'},
    { displayText: 'MICRONESIA(+691)', id: '691'},
    { displayText: 'MOLDOVA(+373)', id: '373'},
    { displayText: 'MONACO(+377)', id: '377'},
    { displayText: 'MONGOLIA(+976)', id: '976'},
    { displayText: 'MONTENEGRO(+382)', id: '382'},
    { displayText: 'MONTSERRAT(+1-664)', id: '1-664'},
    { displayText: 'MOROCCO/WESTERN SAHARA(+212)', id: '212'},
    { displayText: 'MOZAMBIQUE(+258)', id: '258'},
    { displayText: 'MYANMAR(+95)', id: '95'},
    { displayText: 'NAMIBIA(+264)', id: '264'},
    { displayText: 'NAURU(+674)', id: '674'},
    { displayText: 'NEPAL(+977)', id: '977'},
    { displayText: 'NETHERLANDS(+31)', id: '31'},
    { displayText: 'NEW CALEDONIA(+687)', id: '687'},
    { displayText: 'NEW ZEALAND/PITCAIRN(+64)', id: '64'},
    { displayText: 'NICARAGUA(+505)', id: '505'},
    { displayText: 'NIGER(+227)', id: '227'},
    { displayText: 'NIGERIA(+234)', id: '234'},
    { displayText: 'NIUE(+683)', id: '683'},
    { displayText: 'NORTH KOREA(+850)', id: '850'},
    { displayText: 'NORTHERN MARIANA ISLANDS(+1-670)', id: '1-670'},
    { displayText: 'NORWAY/SVALBARD AND JAN MAYEN(+47)', id: '47'},
    { displayText: 'OMAN(+968)', id: '968'},
    { displayText: 'PAKISTAN(+92)', id: '92'},
    { displayText: 'PALAU(+680)', id: '680'},
    { displayText: 'PALESTINE(+970)', id: '970'},
    { displayText: 'PANAMA(+507)', id: '507'},
    { displayText: 'PAPUA NEW GUINEA(+675)', id: '675'},
    { displayText: 'PARAGUAY(+595)', id: '595'},
    { displayText: 'PERU(+51)', id: '51'},
    { displayText: 'PHILIPPINES(+63)', id: '63'},
    { displayText: 'POLAND(+48)', id: '48'},
    { displayText: 'PORTUGAL(+351)', id: '351'},
    { displayText: 'PUERTO RICO(+1-787, 1-939)', id: '1-787, 1-939'},
    { displayText: 'QATAR(+974)', id: '974'},
    { displayText: 'REPUBLIC OF THE CONGO(+242)', id: '242'},
    { displayText: 'ROMANIA(+40)', id: '40'},
    { displayText: 'RUSSIA/KAZAKHSTAN(+7)', id: '7'},
    { displayText: 'RWANDA(+250)', id: '250'},
    { displayText: 'SAINT BARTHELEMY/SAINT MARTIN(+590)', id: '590'},
    { displayText: 'SAINT HELENA(+290)', id: '290'},
    { displayText: 'SAINT KITTS AND NEVIS(+1-869)', id: '1-869'},
    { displayText: 'SAINT LUCIA(+1-758)', id: '1-758'},
    { displayText: 'SAINT PIERRE AND MIQUELON(+508)', id: '508'},
    { displayText: 'SAINT VINCENT AND THE GRENADINES(+1-784)', id: '1-784'},
    { displayText: 'SAMOA(+685)', id: '685'},
    { displayText: 'SAN MARINO(+378)', id: '378'},
    { displayText: 'SAO TOME AND PRINCIPE(+239)', id: '239'},
    { displayText: 'SAUDI ARABIA(+966)', id: '966'},
    { displayText: 'SENEGAL(+221)', id: '221'},
    { displayText: 'SERBIA(+381)', id: '381'},
    { displayText: 'SEYCHELLES(+248)', id: '248'},
    { displayText: 'SIERRA LEONE(+232)', id: '232'},
    { displayText: 'SINGAPORE(+65)', id: '65'},
    { displayText: 'SINT MAARTEN(+1-721)', id: '1-721'},
    { displayText: 'SLOVAKIA(+421)', id: '421'},
    { displayText: 'SLOVENIA(+386)', id: '386'},
    { displayText: 'SOLOMON ISLANDS(+677)', id: '677'},
    { displayText: 'SOMALIA(+252)', id: '252'},
    { displayText: 'SOUTH AFRICA(+27)', id: '27'},
    { displayText: 'SOUTH KOREA(+82)', id: '82'},
    { displayText: 'SOUTH SUDAN(+211)', id: '211'},
    { displayText: 'SPAIN(+34)', id: '34'},
    { displayText: 'SRI LANKA(+94)', id: '94'},
    { displayText: 'SUDAN(+249)', id: '249'},
    { displayText: 'SURINAME(+597)', id: '597'},
    { displayText: 'SWAZILAND(+268)', id: '268'},
    { displayText: 'SWEDEN(+46)', id: '46'},
    { displayText: 'SWITZERLAND(+41)', id: '41'},
    { displayText: 'SYRIA(+963)', id: '963'},
    { displayText: 'TAIWAN(+886)', id: '886'},
    { displayText: 'TAJIKISTAN(+992)', id: '992'},
    { displayText: 'TANZANIA(+255)', id: '255'},
    { displayText: 'THAILAND(+66)', id: '66'},
    { displayText: 'TOGO(+228)', id: '228'},
    { displayText: 'TOKELAU(+690)', id: '690'},
    { displayText: 'TONGA(+676)', id: '676'},
    { displayText: 'TRINIDAD AND TOBAGO(+1-868)', id: '1-868'},
    { displayText: 'TUNISIA(+216)', id: '216'},
    { displayText: 'TURKEY(+90)', id: '90'},
    { displayText: 'TURKMENISTAN(+993)', id: '993'},
    { displayText: 'TURKS AND CAICOS ISLANDS(+1-649)', id: '1-649'},
    { displayText: 'TUVALU(+688)', id: '688'},
    { displayText: 'U.S. VIRGIN ISLANDS(+1-340)', id: '1-340'},
    { displayText: 'UGANDA(+256)', id: '256'},
    { displayText: 'UKRAINE(+380)', id: '380'},
    { displayText: 'UNITED ARAB EMIRATES(+971)', id: '971'},
    { displayText: 'UNITED KINGDOM(+44)', id: '44'},
    { displayText: 'UNITED STATES/CANADA(+1)', id: '1'},
    { displayText: 'URUGUAY(+598)', id: '598'},
    { displayText: 'UZBEKISTAN(+998)', id: '998'},
    { displayText: 'VANUATU(+678)', id: '678'},
    { displayText: 'VATICAN(+379)', id: '379'},
    { displayText: 'VENEZUELA(+58)', id: '58'},
    { displayText: 'VIETNAM(+84)', id: '84'},
    { displayText: 'WALLIS AND FUTUNA(+681)', id: '681'},
    { displayText: 'YEMEN(+967)', id: '967'},
    { displayText: 'ZAMBIA(+260)', id: '260'},
    { displayText: 'ZIMBABWE(+263)', id: '263'}];  
    
    const emailId = 'testEmail@pepsico.com'; 

    const resetTypeSelected = (type) => {

        setOtpType(type);
        if (type === 'otp') {
            setSmsOTP(true);
        }
        else if (type === 'voice') {
            setCallOTP(true);

        }
        else if (type === 'email') {
            setEmailOTP(true);

        }
      }
 
      
      const handleMobileNumberChange = (event) => {
        console.log(event);
        const mobile = event.target.value.replace(/[^0-9]/g, ''); // Regex to remove non-numeric characters
        setMobileNumber(mobile);
    }      
    const handlePhoneNumberChange = (event) => {
        console.log(event);
        const phone = event.target.value.replace(/[^0-9]/g, ''); // Regex to remove non-numeric characters
        setPhoneNumber(phone);
    }
    const handleEmailChange = (event) => {
        console.log(event);
   //     const onlyNumbersOtp = event.target.value.replace(/[^0-9]/g, ''); // Regex to remove non-numeric characters
        setNonPepEmail(event.target.value);
    }
    const handleOTP = (event) => {
        console.log(event);
        const onlyNumbersOtp = event.target.value.replace(/[^0-9]/g, ''); // Regex to remove non-numeric characters
        setVerificationCode(onlyNumbersOtp);
        if(onlyNumbersOtp.length === 8)
        {
            setVerifyButtonDisabled(false);
        }
        else{
            setVerifyButtonDisabled(true);
        }
    }    
     
    const sendSmsVerificationCode =() => {

        axios.post(REACT_APP_API_ENDPOINT + 'user/sendOTP ', {
            "gpid": userGpId,
            "mobileNumber" : mobileNumber,
            "channelType" : "Mobile",
            "countryCode" : "+"+voiceCountryCode
        }).then(response => {
            if (response?.data?.responseStatus === 'Success') {
                setUpdateStatus(true);
            }
            else {
                setUpdateStatus(false);
            }
        }).then(data => console.log({ data }))
            .catch(error => {
                // Handle error (e.g., display error message)
                console.error('Error fetching data:', error?.response?.data?.message);
            });
    }

    const sendCallVerificationCode =() => {

        axios.post(REACT_APP_API_ENDPOINT + 'user/sendOTP ', {
            "gpid": userGpId,
            "mobileNumber" : mobileNumber,
            "channelType" : "Voice",
            "countryCode" : "+"+voiceCountryCode
        }).then(response => {
            if (response?.data?.responseStatus === 'Success') {
                setUpdateStatus(true);
            }
            else {
                setUpdateStatus(false);
            }
        }).then(data => console.log({ data }))
            .catch(error => {
                // Handle error (e.g., display error message)
                console.error('Error fetching data:', error?.response?.data?.message);
            });
    }

    const sendEmailVerificationCode =() => {

        axios.post(REACT_APP_API_ENDPOINT + 'user/sendOTP ', {
            "gpid": userGpId,
            "emailId" : nonPepEmail,
            "channelType" : "Email"
          }).then(response => {
            if (response?.data?.responseStatus === 'Success') {
                setUpdateStatus(true);
            }
            else {
                setUpdateStatus(false);
            }
        }).then(data => console.log({ data }))
            .catch(error => {
                // Handle error (e.g., display error message)
                console.error('Error fetching data:', error?.response?.data?.message);
            });
    }

    const verifyAndUpdate =(type) => {

        let verifyrequest = {
            "gpid": userGpId,
            "pepOTP" : verificationCode,
            "channelType" : type,
            "countryCode" : voiceCountryCode,
            "mobileNumber" : mobileNumber
            
          };
        if(type === "Email")
        {
             verifyrequest = {
                "gpid": userGpId,
                "pepOTP" : verificationCode,
                "channelType" : type,
                "emailId" : nonPepEmail
              };
        }
        axios.post(REACT_APP_API_ENDPOINT + 'user/updatePhoneMail ', verifyrequest).then(response => {
            if (response?.data?.responseStatus === 'Success') {
                setUpdateStatus(true);
            }
            else {
                setUpdateStatus(false);
            }
        }).then(data => console.log({ data }))
            .catch(error => {
                // Handle error (e.g., display error message)
                console.error('Error fetching data:', error?.response?.data?.message);
            });
    }
    
    return (

<div id='updatePhone'>
<div style={{width: '100%'}}>
    <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
        <div style={{color: '#3A3A3A', fontSize: 28, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Profile Update (Phone and non-PepsiCo email) using One-Time Passcode: Phone (Voice) Update</div>
    </div>
    <div />
</div>

<TabsNavigation
  onUpdate={(val) => selectedTab(val)}
  selectedIndex={selectedTabIndex}>
  <Tab label="Associate & Job Info" />
  <Tab label="Contractor Information" />
</TabsNavigation>
{selectedTabIndex === 0 && !editMode && stepNumber === 0 &&
 <div>
    <div className='link font-700 mt-4'>Mobile (SMS/Text) Update</div>
        <div className='d-flex w-100 justify-content-between mt-2'>
            <div className='dynamic-width' style={{ '--dynamic-width': '30%' }}>
                <div className='detailsValue font-700'>Phone type</div>
                <div className='detailInfo'>Mobile</div>
            </div>
            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                <div className='detailsValue font-700'>Country Code</div>
                <Dropdown
                    childList={childList}
                    dropdownHeader="Country Code"
                    feedbackType="info"
                    isRequired
                    label='Country Code'
                    selection="single"
                    setSelectedValue={(val) => setSmsCountryCode(val[0].id)}
                    size="medium"
                />            </div>
            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                <div className='detailsValue font-700'>Phone number for SMS/Text OTP verification</div>
                <div className='detailInfo'>Details</div>
            </div>
     </div>
     <br></br>

     <ProgressIndicator
        currentStep={2}
        orientation="horizontal"
        showStepNumber
        steps={3}
        titles={[
            {
            description: 'By SMS/text',
            title: 'Mobile Update'
            },
            {
            description: 'By Voice',
            title: 'Phone Update'
            },
            {
            description: 'By email',
            title: 'Email Update'
            }
        ]}
        />
     <br></br>
     <br></br>
     <br></br>
     <div style={{width: '100%', textAlign: 'center', color: 'black', fontSize: 16, fontFamily: 'Inter', fontWeight: '500', lineHeight: 1, wordWrap: 'break-word'}}>To receive your one-time passcode, please select one of the following options:</div>
    <br></br>
    { !otpType &&
     <div className='selectionInfo'>
              <div className='selectionItem'  onClick={() => resetTypeSelected('otp')}>
                <div className='selectionSmsIcon'></div>
                <div>SMS message</div>
              </div>
              <div className='selectionItem'  onClick={() => resetTypeSelected('voice')}>
                <div className='selectionCallIcon'></div>
                <div>Call Phone</div>
              </div>
              <div className='selectionItem'  onClick={() => resetTypeSelected('email')}>
                <div className='selectionEmailIcon'></div>
                <div>Non Pepsico e-mail </div>
              </div>
            </div>
    }
    { smsOTP && 
       <div className='d-flex w-100 justify-content-between mt-2'>
                <div className='dynamic-width' style={{ '--dynamic-width': '50%' }}>
                <Dropdown
                        childList={childList}
                        dropdownHeader="Preferred Language"
                        feedbackType="info"
                        isRequired
                        label='Country Code'
                        selection="single"
                        setSelectedValue={(val) => setVoiceCountryCode(val[0].id)}
                        size="medium"
                    />
                    <div className='detailsValue font-700'>Mobile</div>
                    <TextInput
                        onUpdate={doNothing}
                        placeholderText="content"
                        required
                        onChange={handleMobileNumberChange}
                        />
                     <Button
                        size="small"
                        text="Send code"
                        variant="primary"
                        onClick={sendSmsVerificationCode} 
                    />   
                    <div className='detailsValue font-700'>Enter the verification code</div>
                    <TextInput
                        onUpdate={doNothing}
                        placeholderText="content"
                        required
                        onChange={handleOTP}
                    /> 
                    <Button
                        size="small"
                        text="Verify and update"
                        variant="primary"
                        disabled={verifyButtonDisabled}
                        onClick={()=> verifyAndUpdate("Mobile")} 
                    /> 
                </div>
    
        </div>
    

    }
    { callOTP && 
       <div className='d-flex w-100 justify-content-between mt-2'>
        <div className='dynamic-width' style={{ '--dynamic-width': '50%' }}>
        <Dropdown
                childList={childList}
                dropdownHeader="Preferred Language"
                feedbackType="info"
                isRequired
                label='Country Code'
                selection="single"
                setSelectedValue={(val) => setVoiceCountryCode(val[0].id)}
                size="medium"
            />
            <div className='detailsValue font-700'>Phone Number</div>
            <TextInput
                onUpdate={doNothing}
                placeholderText="content"
                required
                onChange={handlePhoneNumberChange}
                />
                <Button
                size="small"
                text="Send code"
                variant="primary"
                onClick={sendCallVerificationCode} 
            /> 
            <div className='detailsValue font-700'>Enter the verification code</div>
                <TextInput
                    onUpdate={doNothing}
                    placeholderText="content"
                    required
                    onChange={handleOTP}
                /> 
                <Button
                    size="small"
                    text="Verify and update"
                    variant="primary"
                    disabled={verifyButtonDisabled}
                    onClick={()=> verifyAndUpdate("Voice")} 
                /> 

        </div>

    </div>
    }
    { emailOTP && 
       <div className='d-flex w-100 justify-content-between mt-2'>
                <div className='dynamic-width' style={{ '--dynamic-width': '50%' }}>
                    <div className='detailsValue font-700'>Non Pepsico EmailID</div>
                    <TextInput
                        onUpdate={doNothing}
                        placeholderText="content"
                        required
                        onChange={handleEmailChange}
                        />
                     <Button
                        size="small"
                        text="Send code"
                        variant="primary"
                        onClick={sendEmailVerificationCode} 
                    />
                            <div className='detailsValue font-700'>Enter the verification code</div>
                <TextInput
                    onUpdate={doNothing}
                    placeholderText="content"
                    required
                    onChange={handleOTP}
                /> 
                <Button
                    size="small"
                    text="Verify and update"
                    variant="primary"
                    disabled={verifyButtonDisabled}
                    onClick={()=> verifyAndUpdate("Email")} 
                />     

                </div>
    
        </div>
    

    }
     <br></br>
        <div style={{ display: 'flex', gap: '8px', paddingLeft: '900px' }}>
            <Button onClick={() => setStepNumber(1)} size='medium' className="full-button" disabled={false}>Next</Button>
            <Button className='full-button' size="medium" text='Cancel'  disabled={true}/>
        </div>


</div>
}

{selectedTabIndex === 0 && !editMode && stepNumber === 1 &&
 <div>
    <div className='link font-700 mt-4'>Phone (Voice) Update</div>
        <div className='d-flex w-100 justify-content-between mt-2'>
            <div className='dynamic-width' style={{ '--dynamic-width': '30%' }}>
                <div className='detailsValue font-700'>Phone type</div>
                <div className='detailInfo'>Mobile</div>
            </div>
            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                <div className='detailsValue font-700'>Country Code</div>
                <Dropdown
                    childList={childList}
                    dropdownHeader="Preferred Language"
                    feedbackType="info"
                    isRequired
                    label='Country Code'
                    selection="single"
                    setSelectedValue={(val) => setVoiceCountryCode(val[0].id)}
                    size="medium"
                />
            </div>
            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                <div className='detailsValue font-700'>Phone number for SMS/Text OTP verification</div>
                <div className='detailInfo'>Details</div>
            </div>
     </div>
     <br></br>
        <div style={{ display: 'flex', gap: '8px', paddingLeft: '900px' }}>
            <Button onClick={() => setStepNumber(0)} size='medium' className="full-button" disabled={false}>Previous</Button>
            <Button onClick={() => setStepNumber(2)} className='full-button' size="medium" text='Next'  disabled={false}/>
            <Button className='full-button' size="medium" text='Cancel'  disabled={true}/>
        </div>


</div>
}
{selectedTabIndex === 0 && !editMode && stepNumber === 2 &&
 <div>
    <div className='link font-700 mt-4'>Email</div>
        <div className='d-flex w-100 justify-content-between mt-2'>
            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                <div className='detailsValue font-700'>Non-PepsiCo email for OTP verification</div>
                <TextInput
                    onUpdate={doNothing}
                    placeholderText="content"
                    required
                 />
            </div>
            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                <div className='detailsValue font-700'>Enter OTP received by email</div>
                <TextInput
                    onUpdate={doNothing}
                    placeholderText="content"
                    required
                 />
            </div>
     </div>
     <br></br>
        <div style={{ display: 'flex', gap: '8px', paddingLeft: '900px' }}>
            <Button onClick={() => setStepNumber(1)} size='medium' className="full-button" disabled={false}>Previous</Button>
            <Button className='full-button' size="medium" text='Submit'  disabled={false}/>
            <Button className='full-button' size="medium" text='Cancel'  disabled={true}/>
        </div>


</div>
}
{selectedTabIndex === 0 && editMode &&
    <div>
    <div className='link font-700 mt-4'>Associate Info</div>
        <div className='d-flex w-100 justify-content-between mt-2'>
            <div className='dynamic-width' style={{ '--dynamic-width': '30%' }}>
                <div className='detailsValue font-700'>GPID</div>
                <div className='detailInfo'>09455729</div>
            </div>
            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                <div className='detailsValue font-700'>First Name</div>
                <TextInput
                    onUpdate={doNothing}
                    placeholderText="content"
                    required
                    />
            </div>
            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                <div className='detailsValue font-700'>Pronouns</div>
                <TextInput
                    onUpdate={doNothing}
                    placeholderText="content"
                    required
                    />
            </div>
            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                <div className='detailsValue font-700'>Middle Initial</div>
                <TextInput
                    onUpdate={doNothing}
                    placeholderText="content"
                    required
                    />
            </div>
            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                <div className='detailsValue font-700'>Last Name</div>
                <TextInput
                    onUpdate={doNothing}
                    placeholderText="content"
                    required
                    />
            </div>
            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                <div className='detailsValue font-700'>Displayed First Name</div>
                <TextInput
                    onUpdate={doNothing}
                    placeholderText="content"
                    required
                    />
            </div>
            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                <div className='detailsValue font-700'>Displayed Last Name</div>
                <TextInput
                    onUpdate={doNothing}
                    placeholderText="content"
                    required
                    />
            </div>
    </div>

    <div className='link font-700 mt-4'>Job Info</div>
        <div className='d-flex w-100 justify-content-between mt-2'>
            <div className='dynamic-width' style={{ '--dynamic-width': '30%' }}>
                <div className='detailsValue font-700'>Associate Type</div>
                <TextInput
                    onUpdate={doNothing}
                    placeholderText="content"
                    required
                    />
            </div>
            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                <div className='detailsValue font-700'>Reporting Manager</div>
                <TextInput
                    onUpdate={doNothing}
                    placeholderText="content"
                    required
                    />
            </div>
            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                <div className='detailsValue font-700'>Job function</div>
                <TextInput
                    onUpdate={doNothing}
                    placeholderText="content"
                    required
                    />
            </div>
            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                <div className='detailsValue font-700'>Division</div>
                <TextInput
                    onUpdate={doNothing}
                    placeholderText="content"
                    required
                    />
            </div>
            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                <div className='detailsValue font-700'>Subdivision</div>
                <TextInput
                    onUpdate={doNothing}
                    placeholderText="content"
                    required
                    />
            </div>
            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                <div className='detailsValue font-700'>Office phone</div>
                <TextInput
                    onUpdate={doNothing}
                    placeholderText="content"
                    required
                    />
            </div>
            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                <div className='detailsValue font-700'>Extension</div>
                <TextInput
                    onUpdate={doNothing}
                    placeholderText="content"
                    required
                    />
            </div>
    </div>

    <div className='link font-700 mt-4'>Other</div>
        <div className='d-flex w-100 mt-2'>
            <div className='dynamic-width' style={{ '--dynamic-width': '17%' }}>
                <div className='detailsValue font-700'>Registered</div>
                <div className='detailInfo'>Details</div>
            </div>
            <div className='dynamic-width' style={{ '--dynamic-width': '17%' }}>
                <div className='detailsValue font-700'>Preferred Language</div>
                <div className='detailInfo'>Details</div>
            </div>

    </div>
    <div className='d-flex w-100 mt-2'>
    <div className='dynamic-width' style={{ '--dynamic-width': '40%'}}>
    
        <Button
        size="small"
        text="Edit mode"
        variant="primary"
        onClick={selectEditMode} 
        />
        </div>
        </div>
    </div>
}
</div>

        )
    };
    export default UpdatePhone;